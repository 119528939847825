<script lang="ts">
  import { signInOneTimePassword, signInWithGoogle } from '$lib/authentication/firebase'
  import modal from '$lib/stores/modal-state'
  import { Exclamation } from '@steeze-ui/heroicons'
  import { Icon } from '@steeze-ui/svelte-icon'
  import { track } from 'src/utils/track'
  import { createEventDispatcher } from 'svelte'
  import EmailPrompt from './EmailPrompt.svelte'

  let authErrorMessage: string | null = null

  let error: Error | string | null = null
  export let otpSignedUp = false
  export let loading = false
  export let isBusy = false
  let email: string | null = null
  export let isAuthenticating = false

  const dispatch = createEventDispatcher()

  function handleGoogleSignIn(event: MouseEvent) {
    event.preventDefault()
    isBusy = true
    signInWithGoogle()
      .then((result) => {
        if (result != null) {
          modal.close()
        }
        track('Clicked - Sign In with Google - Modal')
        dispatch('completed')
      })
      // Note: setting this is necessary for
      // redirection so that
      // afterSignInRedirect() is called above
      .finally(() => (isBusy = false))
  }
  function submitOtp(event: CustomEvent) {
    track('SignIn OTP Presubmit')
    loading = true
    const url = window.location.href
    signInOneTimePassword(email, url)
      .then(() => {
        loading = false
        otpSignedUp = true
        track('SignIn OTP')
      })
      .catch((err) => {
        loading = false
        error = err
        track('SignIn OTP Error', { error: err?.toString() })
      })
  }

  function setValue(event: CustomEvent) {
    email = event.detail.value
  }
</script>

<div class="min-w-min">
  <div class="sm:flex sm:items-start">
    <div class="w-full sm:mt-0 sm:text-left">
      <slot />

      {#if authErrorMessage}
        <p class="px-2 py-1 text-red-500 bg-red-100 rounded">{authErrorMessage}</p>
      {/if}

      {#if !error && !otpSignedUp}
        <div class="items-center ">
          <button
            aria-label="Sign in with Google"
            class="flex items-center justify-center w-full h-12 p-1 pl-2 border rounded-full space-x-2 border-grey-md focus:outline-none hover:bg-grey-main hover:shadow-sm"
            disabled={isBusy}
            on:click={handleGoogleSignIn}
          >
            <img
              height={24}
              width={24}
              src={'/marketing/icons/social/google-color.svg'}
              alt="Google logo"
              class="h-6"
            />
            <p>Continue with Google</p>
          </button>

          <div class="relative my-4">
            <div class="absolute inset-0 flex items-center" aria-hidden="true">
              <div class="w-full border-t border-gray-300" />
            </div>
            <div class="relative flex justify-center">
              <span class="px-2 text-sm text-gray-600 bg-white">OR</span>
            </div>
          </div>

          <EmailPrompt
            buttonLabel="Send Confirmation Code"
            on:submit={submitOtp}
            on:change={setValue}
            value={email}
            loading={loading || isAuthenticating}
          />
        </div>
      {/if}
      {#if otpSignedUp}
        <div class="p-4 rounded-md bg-yellow-50">
          <div class="flex">
            <div class="flex-shrink-0">
              <Icon src={Exclamation} class="w-5 h-5 text-yellow-400" aria-hidden="true" />
            </div>
            <div class="ml-3">
              <p class="text-base font-normal text-black">
                We sent a verification code to <span class="font-medium">{email}</span>
              </p>
              <p>Please click the link to sign-in.</p>
            </div>
          </div>
        </div>
      {/if}
      {#if error && !loading}
        <div class="py-2">
          <p class="font-medium tracking-wide text-brand-primary">An Error Occurred</p>
          <p>There was a problem logging in. Please try again.</p>
        </div>
      {/if}
    </div>
  </div>
</div>
