<script lang="ts">
  import { validateEmail } from 'src/utils/validate-email'
  import { createEventDispatcher } from 'svelte'
  import Button from '../ui/buttons/Button.svelte'
  import TextField from '../ui/forms/TextField.svelte'

  export let loading = false
  export let formValid = false
  export let buttonLabel = 'Sign In'
  export let value = ''

  const dispatch = createEventDispatcher()

  function handleClick(event: CustomEvent<unknown>) {
    event.preventDefault()

    const isValid = validateEmail(value)
    if (isValid) {
      dispatch('submit', { value })
    }
  }
  function onBlur(event: FocusEvent) {
    event.preventDefault()
    dispatch('blur')
  }
  function onPaste(event: ClipboardEvent) {
    event.preventDefault()
    dispatch('paste')
  }
  function handleChange(event: InputEvent) {
    event.preventDefault()
    const value = (event.target as HTMLInputElement)?.value?.trim()
    handleValue(value)
  }

  function handleKeyDown(event: KeyboardEvent) {
    const isValid = validateEmail((event.target as HTMLInputElement)?.value?.trim())
    if (event.key === 'Enter' && isValid) {
      dispatch('submit', { value })
    }
  }

  function handleValue(value: string) {
    const isValid = validateEmail(value)
    formValid = isValid
    dispatch('change', { value, isValid })
  }
</script>

<div class="flex flex-col items-stretch w-full mt-2 space-y-2">
  <TextField
    type="email"
    name="email"
    required
    autoComplete="email"
    placeholder="Enter your email"
    on:blur={onBlur}
    on:paste={onPaste}
    on:input={handleChange}
    on:keydown={handleKeyDown}
    {value}
  />
  <Button
    aria-label="submit"
    on:click={handleClick}
    disabled={!formValid}
    size="md"
    class="w-full rounded-full sm:w-auto"
    {loading}
  >
    {buttonLabel}
  </Button>
</div>
