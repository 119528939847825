<script>
  import { page } from '$app/stores'
  import modal from '$lib/stores/modal-state'
  import Clipboard from 'svelte-clipboard'
  import Tooltip from '../Tooltip.svelte'
  import Title from '../typography/Title.svelte'
  import Button from '../ui/Button.svelte'
  import Modal from './Modal.svelte'

  const copyIconSrc = '/marketing/icons/copy.svg'
  const facebookIconSrc = '/marketing/icons/social/facebook.svg'
  const linkedinIconSrc = '/marketing/icons/social/linkedin.svg'
  const redditIconSrc = '/marketing/icons/social/reddit.svg'
  const telegramIconSrc = '/marketing/icons/social/telegram.svg'
  const twitterIconSrc = '/marketing/icons/social/twitter.svg'
  const whatsappIconSrc = '/marketing/icons/social/whatsapp.svg'

  let isCoppied = false

  function handleCopy() {
    isCoppied = true
  }

  let url = encodeURIComponent($page.url.toString())
  let title = encodeURIComponent($page.data.title)
</script>

<Modal open={true} title="Share">
  <div class="grid grid-cols-3 gap-4 mx-2 my-8 sm:gap-8 sm:mx-8">
    <div class="flex items-center justify-center col-span-1 cursor-pointer">
      <Tooltip title="Share on Twitter">
        <a
          href="https://twitter.com/intent/tweet/?text={title}&amp;url={url}"
          target="_blank"
          rel="noopener"
          aria-label="Twitter"
        >
          <img width={56} height={56} class="w-14 h-14" src={twitterIconSrc} alt="Twitter logo" />
        </a>
      </Tooltip>
    </div>

    <div class="flex items-center justify-center col-span-1 cursor-pointer">
      <Tooltip title="Share on Reddit">
        <a
          href="https://reddit.com/submit/?url={url}&amp;resubmit=true&amp;title={title}"
          target="_blank"
          rel="noopener"
          aria-label="Reddit"
        >
          <img width={56} height={56} class="w-14 h-14" src={redditIconSrc} alt="Twitter logo" />
        </a>
      </Tooltip>
    </div>
    <div class="flex items-center justify-center col-span-1 cursor-pointer">
      <Tooltip title="Share on Facebook">
        <a
          href="https://facebook.com/sharer/sharer.php?u={url}"
          target="_blank"
          rel="noopener"
          aria-label="Facebook"
        >
          <img width={56} height={56} class="w-14 h-14" src={facebookIconSrc} alt="Facebook logo" />
        </a>
      </Tooltip>
    </div>

    <div class="flex items-center justify-center col-span-1 cursor-pointer">
      <Tooltip title="Share on Whatsapp">
        <a
          href="whatsapp://send?text={title}%20{url}"
          target="_blank"
          rel="noopener"
          aria-label="WhatsApp"
        >
          <img width={56} height={56} class="w-14 h-14" src={whatsappIconSrc} alt="Whatsapp logo" />
        </a>
      </Tooltip>
    </div>
    <div class="flex items-center justify-center col-span-1 cursor-pointer">
      <Tooltip title="Share on Telegram">
        <a
          href="https://telegram.me/share/url?text={title}&amp;url={url}"
          target="_blank"
          rel="noopener"
          aria-label="Telegram"
        >
          <img width={56} height={56} class="w-14 h-14" src={telegramIconSrc} alt="Whatsapp logo" />
        </a>
      </Tooltip>
    </div>

    <div class="flex items-center justify-center col-span-1 cursor-pointer">
      <Tooltip title="Share on LinkedIn">
        <a
          href="https://www.linkedin.com/shareArticle?mini=true&amp;url={url}&amp;title={title};source={url}"
          target="_blank"
          rel="noopener"
          aria-label="LinkedIn"
        >
          <img width={56} height={56} class="w-14 h-14" src={linkedinIconSrc} alt="LinkedIn logo" />
        </a>
      </Tooltip>
    </div>
  </div>

  <Title>Page URL</Title>

  <Clipboard text={$page.url.toString()} let:copy on:copy={handleCopy}>
    <div
      class={`${
        isCoppied
          ? 'ring-brand-secondary ring-2 duration-200 ease-out'
          : 'ring-transparent duration-100 ease-in'
      }  transition transform flex items-center justify-between rounded bg-grey-main h-10 p-4 mt-2 cursor-pointer`}
    >
      <div class="text-xs text-gray-600">
        <p class="w-48 truncate sm:w-72">{$page.url.toString()}</p>
      </div>
      <button on:click={copy} class="flex-shrink-0 ml-4">
        <img src={copyIconSrc} alt="Copy" />
      </button>
    </div>
  </Clipboard>
  <p
    class={`${
      isCoppied ? 'absolute' : 'hidden'
    } flex justify-start p-2 text-sm text-brand-secondary font-medium`}
  >
    Copied!
  </p>

  <svelte:fragment slot="actions">
    <Button
      aria-label="close"
      key="close-btn"
      size="md"
      on:click={() => modal.close()}
      class="w-full col-span-3 sm:col-span-1 sm:col-start-3"
    >
      Done
    </Button>
  </svelte:fragment>
</Modal>
