<script lang="ts">
  import type { MaxOccupancy } from 'src/utils/stays/occupancy'
  import GuestSelectForm from '../hotels/GuestSelectForm.svelte'
  import Modal from './Modal.svelte'

  export let adults: number
  export let children: number[]
  export let maxOccupancy: MaxOccupancy = null
</script>

<Modal open={true} isFullScreen={true} showClose={true}>
  <GuestSelectForm {adults} {children} {maxOccupancy} on:change />
  <slot />
</Modal>
