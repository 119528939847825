<script lang="ts">
  import type { Day } from '$lib/gen/rates/v1/calendar_pb'
  import { formatDateRangeShort } from 'src/utils/calendar'
  import { pluralize } from 'src/utils/text-helpers'
  import Calendar from '../hotels/Calendar.svelte'
  import Modal from './Modal.svelte'

  export let checkIn: Date
  export let checkOut: Date
  export let priceCalendar: Day[] = []
  export let firstQuantile: number = null
  export let lastQuantile: number = null
  export let numNights: number
  export let currency: string = 'USD'
  export let isLoadingRates: boolean = false
</script>

<Modal open={true} isFullScreen={true} showClose={true}>
  <div
    slot="header"
    class="grid grid-cols-7 bg-white w-full mx-auto z-20 sticky top-0 text-xs leading-6 text-gray-500"
  >
    <div class="col-span-7 text-black text-sm leading-5 mb-2 flex flex-row space-x-4 items-center">
      <span class=" text-lg">
        {formatDateRangeShort(checkIn, checkOut)}
      </span>
      <span class="text-sm">{pluralize(numNights, 'Night', 'Nights')}</span>
    </div>

    <div class="text-xs col-span-7 h-3 mb-2 mr-8 flex items-center">
      {#if isLoadingRates}
        Loading prices...
      {:else if priceCalendar?.length > 0 && priceCalendar?.some((p) => p.minInclusivePrice > 0)}
        Showing approximate prices in {currency} for 1 night stays, 2 adults, and non-members
      {/if}
    </div>
    <div class="flex items-end justify-center sm:hidden">S</div>
    <div class="flex items-end justify-center sm:hidden">M</div>
    <div class="flex items-end justify-center sm:hidden">T</div>
    <div class="flex items-end justify-center sm:hidden">W</div>
    <div class="flex items-end justify-center sm:hidden">T</div>
    <div class="flex items-end justify-center sm:hidden">F</div>
    <div class="flex items-end justify-center sm:hidden">S</div>
  </div>
  <div class="pt-8" />
  <Calendar
    {checkIn}
    {checkOut}
    {currency}
    {firstQuantile}
    {lastQuantile}
    {priceCalendar}
    {isLoadingRates}
    isMobile={true}
    on:change
  />
  <slot />
</Modal>
