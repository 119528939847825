<script lang="ts" context="module">
  export let filterItems = [
    { name: 'Coworking Spaces', slug: 'coworking', type: 'amenity', value: 1073744898 },
    { name: 'Outdoor Pool', slug: 'pool', type: 'amenity', value: 2821 },
    { name: 'Concierge', slug: 'concierge', type: 'amenity', value: 43 },
    { name: 'Airport Transportation', slug: 'airport-transit', type: 'amenity', value: 2353 },
    { name: 'Elevator', slug: 'elevator', type: 'amenity', value: 8 },
    { name: 'Child Care', slug: 'child care', type: 'amenity', value: 6 },
    {
      name: 'Wheelchair Accessible',
      slug: 'wheelchair-access',
      type: 'disability',
      value: 1073744430,
    },
    {
      name: 'Sign Language Capable Staff',
      slug: 'sign-lang',
      type: 'disability',
      value: 1073744643,
    },
  ]
</script>

<script lang="ts">
  import { page } from '$app/stores'
  import type { TravelSolution } from '$lib/gen/travelsolution/v1/travelsolution_pb'
  import { stageSolution, travelSolutionFromURL, updateSolution } from '$lib/solution/router'
  import modal, { extra } from '$lib/stores/modal-state'
  import Button from '../ui/Button.svelte'
  import HotelFiltersModalContent from './HotelFiltersModalContent.svelte'
  import Modal from './Modal.svelte'

  $: panelItems = $extra['hotel-filters']
  $: isFullScreen = panelItems.isFullScreen

  $: travelSolution = travelSolutionFromURL($page.url)
  // $: accessibility = hotelSolution.query.withAccessibility
  $: amenityCodes = travelSolution.hotelQuery.withAmenityCodes
  $: starRatingRange = [
    travelSolution.hotelQuery.minStarRating == 0 ? 1 : travelSolution.hotelQuery.minStarRating,
    travelSolution.hotelQuery.maxStarRating == 0 ? 5 : travelSolution.hotelQuery.maxStarRating,
  ]

  let stagedSolution: TravelSolution = travelSolution

  async function handleChangeStarRating(event: CustomEvent<{ starRatingRange: number[] }>) {
    const { starRatingRange: newStarRatingRange } = event.detail
    stagedSolution = stageSolution(travelSolution, {
      hotelQuery: {
        minStarRating: newStarRatingRange[0],
        maxStarRating: newStarRatingRange[1],
      },
    })
  }

  async function handleChangeAmenities(event: CustomEvent<{ code: number }>) {
    const { code: newCode } = event.detail
    let newCodes = [...amenityCodes]
    if (amenityCodes.includes(newCode)) {
      newCodes = newCodes.filter((code) => code !== newCode)
    } else {
      newCodes.push(newCode)
    }
    stagedSolution = stageSolution(travelSolution, {
      hotelQuery: {
        withAmenityCodes: newCodes,
      },
    })
  }

  const handleSubmit = async () => {
    await updateSolution($page.url, stagedSolution)
    modal.close()
  }

  async function handleClear() {
    await updateSolution($page.url, {
      hotelQuery: {
        withAmenityCodes: [],
        minStarRating: 0,
        maxStarRating: 0,
      },
    })
  }
</script>

<!-- Using two different calls because the slot fragment can't be used directly in a conditional -->
{#if isFullScreen}
  <Modal
    open={true}
    title="Filter Properties"
    sizeClass="sm:max-w-lg"
    {isFullScreen}
    showClose={true}
  >
    <HotelFiltersModalContent
      on:changeStarRating={handleChangeStarRating}
      on:changeAmenities={handleChangeAmenities}
      {starRatingRange}
      {amenityCodes}
    />
    <div class="flex items-center justify-end gap-x-8" slot="footer">
      <button on:click={handleClear} class="text-sm hover:underline"> clear filters </button>

      <Button
        aria-label="close"
        key="close-btn"
        on:click={handleSubmit}
        class="px-4 py-2 border rounded-full shadow-sm border-grey-md focus:outline-none"
      >
        Show Me
      </Button>
    </div>
  </Modal>
{:else}
  <Modal
    open={true}
    title="Filter Properties"
    sizeClass="sm:max-w-lg"
    {isFullScreen}
    showClose={true}
  >
    <HotelFiltersModalContent
      on:changeStarRating={handleChangeStarRating}
      on:changeAmenities={handleChangeAmenities}
      {starRatingRange}
      {amenityCodes}
    />
    <svelte:fragment slot="actions">
      <span />
      <button on:click={handleClear} class="text-sm hover:underline"> clear filters </button>

      <Button
        aria-label="close"
        key="close-btn"
        on:click={handleSubmit}
        class="px-4 py-2 border rounded-full shadow-sm border-grey-md focus:outline-none"
      >
        Show Me
      </Button>
    </svelte:fragment>
  </Modal>
{/if}
