<script lang="ts">
  import { extra } from '$lib/stores/modal-state'
  import Modal from './Modal.svelte'
  import Title from '../typography/Title.svelte'

  $: panelItems = $extra['hotel-amenities']
  $: groupedAmenities = panelItems.groupedAmenities
</script>

<Modal sizeClass="sm:max-w-3xl" open={true} title={'Property Amenities'} isFullScreen={false}>
  <div class="grid grid-cols-2 col-span-2 gap-8 mt-1 mb-2 lg:grid-cols-3">
    {#each groupedAmenities as amenitiesGroup (amenitiesGroup.category)}
      <div class={amenitiesGroup.amenities.length > 11 ? 'col-span-2' : 'col-span-1'}>
        <Title class="leading-[8px] sm:leading-8">{amenitiesGroup.category}</Title>
        <ul
          class={`mt-1 list-disc pl-4 text-sm space-y-1
          ${amenitiesGroup.amenities.length > 11 ? 'grid grid-cols-2 gap-x-4' : ''}`}
        >
          {#each amenitiesGroup.amenities as amenity (amenity.id)}
            <li>{amenity.name}</li>
          {/each}
        </ul>
      </div>
    {/each}
  </div>
</Modal>
