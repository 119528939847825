<script lang="ts">
  import solution from '$lib/stores/solution'
  import modal, { extra } from '$lib/stores/modal-state'
  import Button from '../ui/Button.svelte'
  import GuestSelectModal from './GuestSelectModal.svelte'
  import { addBreadcrumb } from '@sentry/core'

  $: panelItems = $extra['guest-select']
  $: adults = $solution?.hotelQuery?.adultCount ?? 2
  $: children = $solution?.hotelQuery?.childAges ?? []

  async function handleOccupancyChange(event: CustomEvent<{ adults: number; children: number[] }>) {
    const { adults: newAdults, children: newChildren } = event.detail

    addBreadcrumb({ level: 'info', message: 'Set Guest Selection', data: event.detail })

    return solution.update({
      hotelQuery: {
        adultCount: newAdults,
        childAges: newChildren,
        roomSelections: [],
      },
    })
  }

  function closeModal() {
    modal.close()
  }
</script>

<GuestSelectModal
  maxOccupancy={panelItems?.maxOccupancy}
  {adults}
  {children}
  on:change={handleOccupancyChange}
>
  <div class="pb-24" />
  <div
    class="h-20 z-[11] w-full flex justify-end items-center px-4 py-2 text-black bg-white sm:h-28 border-grey-dk border-t fixed left-0 bottom-0"
  >
    <Button
      aria-label="close"
      key="close-btn"
      on:click={closeModal}
      class="px-4 py-2 border rounded-full shadow-sm border-grey-md focus:outline-none"
    >
      Save
    </Button>
  </div>
</GuestSelectModal>
