<script lang="ts">
  import cdnImageURL, { cdnSrcSetHiDPI } from 'src/utils/cdn'
  import type { PhotoFieldsFragment } from '$lib/queries/fragments/generated/PhotoFields'
  import { createEventDispatcher } from 'svelte'
  import { fade } from 'svelte/transition'
  import { browser } from '$app/environment'

  export let image: PhotoFieldsFragment
  export let didAllLoad: boolean
  export let index: number

  let startedLoading = false

  const dispatch = createEventDispatcher<{
    click: { photo: PhotoFieldsFragment }
    load: void
  }>()

  function handleSelectPhoto(photo: PhotoFieldsFragment) {
    dispatch('click', { photo })
  }

  $: {
    if (browser && image?.url && !startedLoading) {
      startedLoading = true
      let img = new Image()
      img.onerror = (err: string | Event) => {
        console.warn('Preload err', err)
      }
      img.onload = () => {
        dispatch('load')
      }
      img.src = cdnImageURL({ src: image?.url, width: 275, height: 250 })
      img.srcset = cdnSrcSetHiDPI(image?.url, 275, 250)
    }
  }
</script>

<button
  in:fade
  class={`z-20 relative group lg:py-2 flex-col area transition-visibility hidden sm:inline-block ${
    didAllLoad ? 'opacity-100' : 'opacity-0'
  }`}
  on:click|preventDefault|stopPropagation={() => handleSelectPhoto(image)}
>
  <img
    class="object-cover h-full xl:rounded-lg"
    src={cdnImageURL({ src: image?.url, width: 640 })}
    width="640"
    alt={image?.caption ?? 'photo'}
  />
</button>

<div
  in:fade
  class={`z-20 relative group flex-col area transition-visibility sm:hidden ${
    didAllLoad ? 'opacity-100' : 'opacity-0'
  }`}
>
  <img
    class="object-cover h-full"
    src={cdnImageURL({ src: image?.url, width: 640 })}
    width="640"
    alt={image?.caption ?? 'photo'}
  />
</div>
