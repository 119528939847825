<script>
  // import * as Sentry from '@sentry/browser'
  import Plausible from 'plausible-tracker'
  import { onMount } from 'svelte'

  const { enableAutoPageviews } = Plausible({
    domain: 'airheart.com',
    trackLocalhost: false,
    apiHost: 'https://data2.airheart.com/api/event',
  })

  onMount(() => {
    if (import.meta.env.DEV) return
    try {
      const cleanup = enableAutoPageviews()
      return () => {
        cleanup()
      }
    } catch (e) {
      console.error(e)
      // Sentry.captureException(error)
    }
  })
</script>

<!-- <svelte:head> -->
<!-- SimpleAnalytics.com -->
<!-- <script defer src="https://data.airheart.com/latest.js"></script>
  <noscript>
    <img
      src="https://data.airheart.com/noscript.gif"
      alt=""
      referrerPolicy="no-referrer-when-downgrade"
    />
  </noscript> -->

<!-- Plausible -->
<!-- <script
    defer
    data-domain="airheart.com"
    data-api="https://data2.airheart.com/api/event"
    src="https://data2.airheart.com/js/script.js"></script> -->
<!-- </svelte:head> -->
