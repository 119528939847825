<script lang="ts">
  import { navigating } from '$app/stores'
  import { toPBDate } from '$lib/solution/date'
  import modal from '$lib/stores/modal-state'
  import pricePreview from '$lib/stores/pricePreview'
  import solution from '$lib/stores/solution'
  import { toAmount } from '$lib/utils/currency'
  import { addBreadcrumb } from '@sentry/core'
  import { quantile } from 'd3-array'
  import { differenceInCalendarDays } from 'date-fns'
  import Amount from '../ui/Amount.svelte'
  import Button from '../ui/Button.svelte'
  import CalendarModal from './CalendarModal.svelte'
  import HotelSelectFooter from './HotelSelectFooter.svelte'

  $: selectedRooms = $pricePreview.selectedRooms ?? []
  $: totalAmount = $pricePreview.totalAmount ?? 0
  $: totalRooms = $pricePreview.totalRooms ?? 0
  $: isFetching = $pricePreview.isFetching ?? false
  $: priceCalendar = $pricePreview.priceCalendar ?? []
  $: isLoadingRates = $pricePreview.isFetchingRatesCalendar ?? false

  $: checkIn = new Date(
    $solution?.hotelQuery?.checkIn?.year,
    $solution?.hotelQuery?.checkIn?.month - 1,
    $solution?.hotelQuery?.checkIn?.day,
  )
  $: checkOut = new Date(
    $solution?.hotelQuery?.checkOut?.year,
    $solution?.hotelQuery?.checkOut?.month - 1,
    $solution?.hotelQuery?.checkOut?.day,
  )
  $: numNights = differenceInCalendarDays(checkOut, checkIn)
  $: adults = $solution?.hotelQuery?.adultCount ?? 2
  $: children = $solution?.hotelQuery?.childAges ?? []
  $: currency = $solution?.hotelQuery?.currencyCode ?? 'USD'

  $: firstQuantile = quantile(
    priceCalendar
      ?.filter((p) => p.minInclusivePrice != 0)
      ?.map((p) => Number(p.minInclusivePrice / 100)),
    0.15,
  )

  $: lastQuantile = quantile(
    priceCalendar
      ?.filter((p) => p.minInclusivePrice != 0)
      ?.map((p) => Number(p.minInclusivePrice / 100)),
    0.85,
  )

  async function handleDatesChange(event: CustomEvent<{ checkIn: Date; checkOut: Date }>) {
    $pricePreview.hasSelectedDates = true
    const { checkIn: newCheckIn, checkOut: newCheckOut } = event.detail

    addBreadcrumb({ level: 'info', message: 'Set Date Selection', data: event.detail })

    const newNumNights = differenceInCalendarDays(newCheckOut, newCheckIn)
    numNights = newNumNights

    $pricePreview.isFetching = true
    return solution.update({
      hotelQuery: {
        checkIn: toPBDate(newCheckIn),
        checkOut: toPBDate(newCheckOut),
        currencyCode: currency,
        roomSelections: [],
      },
    })
  }

  function closeModal() {
    modal.close()
  }
</script>

<CalendarModal
  {checkIn}
  {checkOut}
  {firstQuantile}
  {lastQuantile}
  {priceCalendar}
  {numNights}
  {currency}
  {isLoadingRates}
  on:change={handleDatesChange}
>
  <div class="pb-32" />
  <HotelSelectFooter
    {adults}
    {children}
    {checkIn}
    {checkOut}
    numSelectedRooms={selectedRooms?.length ?? 0}
    selectedRoomName={selectedRooms?.[0]?.room?.name}
    {totalRooms}
    hideOnLarge={false}
    blockPopUp={true}
  >
    <span slot="price">
      {#if totalRooms > 0 || isFetching}
        {#if selectedRooms.length > 0}
          <Amount
            loading={$navigating != undefined || isFetching}
            amount={toAmount(totalAmount, currency)}
            rounded
          />
          <span class="text-sm sm:mt-[2px] text-gray-800">total</span>
        {:else}
          <Amount
            loading={$navigating != undefined || isFetching}
            amount={toAmount(totalAmount / numNights, currency)}
            rounded
          /><span class="sm:mt-[2px] text-gray-800">+</span>
          <span class="text-sm sm:mt-[2px] text-gray-800">Night</span>
        {/if}
      {:else}
        <span class="text-sm sm:mt-[2px] text-black font-medium">Unavailable</span>
      {/if}
    </span>
    <Button slot="button" class="w-[80px]" on:click={closeModal}>Save</Button>
  </HotelSelectFooter>
</CalendarModal>
