<script lang="ts">
  import { browser } from '$app/environment'
  import { page } from '$app/stores'
  import { otpSignInRedirect } from '$lib/authentication/callbacks'
  import { seamlessAuthClientId } from '$lib/authentication/config'
  import { handleGoogleYoloCredential } from '$lib/authentication/firebase'
  import state from '$lib/stores/auth-state'
  import feature from '$lib/stores/feature'
  import { requestIdleCallback } from '$lib/utils/request-idle-callback'
  import { once } from 'lodash-es'
  import { pinOnLoadHandler } from 'src/utils/restrictions/pinning'
  import { track } from 'src/utils/track'
  import { onDestroy, onMount } from 'svelte'

  $: bannerFeatureEnabled = $feature['banner']
  $: isBannerBlocked = $page.data.blockBanner

  // if (browser) {
  //   // @ts-ignore
  //   window.requestIdleCallback =
  //     window.requestIdleCallback ||
  //     function (cb) {
  //       var start = Date.now()
  //       return setTimeout(function () {
  //         cb({
  //           didTimeout: false,
  //           timeRemaining: function () {
  //             return Math.max(0, 50 - (Date.now() - start))
  //           },
  //         })
  //       }, 1)
  //     }
  //   window.cancelIdleCallback =
  //     window.cancelIdleCallback ||
  //     function (id) {
  //       clearTimeout(id)
  //     }
  // }

  let usingSeamlessSignIn = false

  const yoloConfig = {
    client_id: seamlessAuthClientId,
    context: 'signup',
    prompt_parent_id: 'g_id_container',
    cancel_on_tap_outside: true,
    auto_select: false,
    style: { top: '0', left: '0', padding: 0, position: 'relative' },
    callback: handleGoogleYoloCredential,
  }

  interface Notification {
    getDismissedReason(): string
    getMomentType(): string
    getNotDisplayedReason(): string
    getSkippedReason(): string
    isDismissedMoment(): boolean
    isDisplayMoment(): boolean
    isDisplayed(): boolean
    isNotDisplayed(): boolean
    isSkippedMoment(): boolean
  }

  const initSeamlessLogin = once(function initSeamlessLogin() {
    // @ts-ignore
    if (window?.google == null) return

    if ($state.signedIn || $state.isCompletingSignIn || !$state.authDidInitialize) return
    // @ts-ignore
    window.google.accounts.id.initialize(yoloConfig)

    // @ts-ignore
    window.google.accounts.id.prompt((notification: Notification) => {
      if (notification.isNotDisplayed() || notification.isSkippedMoment()) {
        console.debug('seamless sign in skipped', notification.getNotDisplayedReason())
        track('GoogleYolo Not Displayed', {
          reason: notification.getNotDisplayedReason(),
        })
      } else {
        track('GoogleYolo Displayed', {
          moment: notification.getMomentType(),
        })
      }
      usingSeamlessSignIn = notification.isDisplayed()
    })
  })

  function yoloOnload() {
    initSeamlessLogin()
  }

  let hasAddedScript = false
  let gsiScript: HTMLScriptElement

  onMount(() => {
    requestIdleCallback(() => {
      // Pinned routes handling
      if ($state.signedIn) {
        pinOnLoadHandler()
      }

      // Yolo Auth setup
      if ($state.signedIn) return
      if (hasAddedScript) return
      hasAddedScript = true

      gsiScript = document.createElement('script')
      gsiScript.src = 'https://accounts.google.com/gsi/client'
      gsiScript.type = 'text/javascript'
      gsiScript.async = true
      gsiScript.defer = true
      gsiScript.setAttribute('fetchpriority', 'low')
      document.body.append(gsiScript)
      gsiScript.addEventListener('load', yoloOnload)
    })

    otpSignInRedirect(() => {})
  })

  onDestroy(() => {
    if (gsiScript == null) return
    gsiScript.removeEventListener('load', yoloOnload)
  })
</script>

{#if browser}
  <div
    tabindex={-1}
    class={`fixed hidden sm:flex flex-col items-stretch sm:right-4 ${
      bannerFeatureEnabled && !isBannerBlocked ? 'sm:top-32' : 'sm:top-20'
    } bg-transparent z-[9900] max-w-[391px] align-bottom sm:bottom-auto`}
  >
    {#if usingSeamlessSignIn && !$state.signedIn}
      <div
        id="seamless-intro"
        class="rounded-[8px] px-4 py-3 bg-white select-none relative focus:shadow shadow-resting mx-2"
      >
        <p class="text-sm text-black">
          Join the Airheart waitlist, track changes to travel restrictions, and receive <span
            class="font-semibold">member discounts</span
          > on homes, hotels and hostels.
        </p>
      </div>
    {/if}

    <div id="g_id_container" />
  </div>
{/if}
