<script lang="ts">
  import { goto } from '$app/navigation'

  import { page } from '$app/stores'
  import {
    AllCountriesDocument,
    type AllCountriesQuery,
    type AllCountriesQueryVariables,
  } from '$lib/queries/generated/QueryAllCountries'
  import modal from '$lib/stores/modal-state'
  import { getContextClient, queryStore } from '@urql/svelte'
  import { encodeRestrictionDestinationUrl } from 'src/utils/restrictions/restriction-urls'
  import OriginSelector, { type CountryItem } from '../travel-restrictions/OriginSelector.svelte'
  import Button from '../ui/Button.svelte'
  import Modal from './Modal.svelte'

  $: origin = $page.data.origin
  let selectedDestination: CountryItem = null
  let isLoading = false

  $: selectedOrigin = origin
    ? {
        iconId: origin?.id,
        value: origin?.slug,
        label: origin?.name,
      }
    : null
  $: isValid = selectedDestination?.value != null && selectedDestination?.value != null

  const client = getContextClient()
  $: countriesQuery = queryStore<AllCountriesQuery, AllCountriesQueryVariables>({
    client,
    query: AllCountriesDocument,
  })

  $: origins = $countriesQuery?.data?.countries.map((country) => ({
    iconId: country.id,
    label: country.name,
    value: country.slug,
  }))

  $: destinations = $countriesQuery?.data?.countries
    ?.filter((c) => c.id != origin?.id)
    .map((country) => ({
      iconId: country.id,
      label: country.name,
      value: country.slug,
    }))

  $: if (selectedOrigin == null && origins?.length > 0) {
    selectedOrigin = origins[20]
  }
  $: if (selectedDestination == null && destinations?.length > 0) {
    selectedDestination = destinations[40]
  }

  function handleFormSubmit(e: SubmitEvent) {
    e.preventDefault()
    navigate()
  }

  async function navigate(event?: CustomEvent) {
    let origin = selectedOrigin?.value
    let destination = selectedDestination?.value
    if (origin == null || destination == null) {
      isValid = false
      isLoading = false
      return
    }

    isLoading = true
    let url = encodeRestrictionDestinationUrl(
      origin,
      destination,
      $page.data.vaccination == null ? true : $page.data.vaccination === 'vaccinated',
    )

    requestAnimationFrame(modal.close)
    return goto(url).finally(() => (isLoading = false))
  }

  function handleOriginCountryChange(e: CustomEvent) {
    selectedOrigin = e.detail
  }

  function handleDestinationCountryChange(e: CustomEvent) {
    selectedDestination = e.detail
  }
</script>

<Modal open={true} title="Search Restrictions" overflowScroll={false}>
  <form autoComplete="off" class="flex sm:mx-4 flex-col space-y-4" on:submit={handleFormSubmit}>
    <OriginSelector
      label="Origin country"
      on:change={handleOriginCountryChange}
      value={selectedOrigin}
      items={origins}
    />

    <OriginSelector
      label="Destination country"
      on:change={handleDestinationCountryChange}
      value={selectedDestination}
      items={destinations}
    />
  </form>
  <svelte:fragment slot="actions">
    <Button
      disabled={!isValid}
      loading={isLoading}
      on:click={navigate}
      class="w-full rounded-full col-span-3"
    >
      View Restrictions
    </Button>
  </svelte:fragment>
</Modal>
