<script lang="ts">
  import { extra } from '$lib/stores/modal-state'
  import { page } from '$app/stores'
  import LocationSearchModal from './LocationSearchModal.svelte'
  import { updateSolution } from '$lib/solution/router'

  $: panelItems = $extra['location-search']

  async function handleLocationSelect(event: CustomEvent) {
    await updateSolution($page.url, {
      hotelQuery: {
        locationScopeId: event.detail?.value,
      },
    })
  }
</script>

<LocationSearchModal {...panelItems} on:change={handleLocationSelect} />
