<script lang="ts">
  import { browser } from '$app/environment'
  import type { PhotoFieldsFragment } from '$lib/queries/fragments/generated/PhotoFields'
  import {
    PhotosDocument,
    type PhotosQuery,
    type PhotosQueryVariables,
  } from '$lib/queries/generated/QueryPhotos'
  import { extra } from '$lib/stores/modal-state'
  import { collectNodes } from '$lib/utils/edges'
  import { getContextClient, queryStore } from '@urql/svelte'
  import ImageCarouselFullScreen from '../hotels/ImageCarouselFullScreen.svelte'
  import Spinner from '../Spinner.svelte'
  import MasonryPhotos from './MasonryPhotos.svelte'
  import Modal from './Modal.svelte'
  import PhotoCategory from './PhotoCategory.svelte'

  $: data = $extra['hotel-photos']
  $: photoIndex = data.photoIndex
  $: hotelId = data.hotelId
  $: allPhotos = data.allPhotos
  $: isFetching = data.isFetching
  $: isRoom = data.isRoom

  const pageSize = 250
  const urql = getContextClient()

  $: photosStore = queryStore<PhotosQuery, PhotosQueryVariables>({
    client: urql,
    requestPolicy: 'cache-first',
    query: PhotosDocument,
    variables: {
      where: {
        hasHotelWith: [{ id: hotelId }],
      },
      first: pageSize,
    },
    pause: !browser || hotelId == null,
  })

  $: if (isRoom && isFetching) {
    const photos = collectNodes($photosStore?.data?.photos)
    if (photos?.length > 0) {
      allPhotos = [photos.find((p) => p.isHero), ...photos.filter((p) => !p.isHero)]
    }
  }

  function showPhoto(event: CustomEvent<{ photo: PhotoFieldsFragment }>) {
    photoIndex = allPhotos.indexOf(event.detail.photo)
  }

  function showAllPhotos() {
    photoIndex = null
  }
</script>

<Modal open={true} isFullScreen={true} isBlack={photoIndex == null ? false : true}>
  {#if $photosStore.fetching}
    <div class="flex items-center justify-center min-h-screen col-span-12 text-center text-black">
      <Spinner size="md" />
    </div>
  {:else if photoIndex != null}
    <div class="h-full py-4">
      <button
        disabled={allPhotos.length < 2}
        on:click|preventDefault|stopPropagation={showAllPhotos}
        class={`${
          !isRoom ? 'absolute' : 'hidden'
        } disabled:hidden z-40 flex items-center px-4 py-2 bg-white bg-opacity-0 border border-white rounded-lg cursor-pointer z-2 top-4 left-4 lg:left-8 lg:top-8 hover:bg-opacity-20 gap-x-2 focus:outline-none focus:ring-black`}
      >
        <img
          src="/marketing/icons/photos-white.svg"
          alt="view all photos"
          height={14}
          width={14}
          class="w-[14px] hidden lg:block"
        />

        <img
          src="/marketing/icons/photos-white.svg"
          alt="view all photos"
          height={12}
          width={12}
          class="w-[12px] lg:hidden"
        />
        <span
          class="hidden text-xs leading-8 tracking-tight text-white cursor-pointer md:block lg:text-sm"
          >View All Photos</span
        >
      </button>
      <ImageCarouselFullScreen photos={allPhotos} index={photoIndex} />
    </div>
  {:else if allPhotos.length > 10}
    <PhotoCategory {allPhotos} on:click={showPhoto} />
  {:else}
    <MasonryPhotos {allPhotos} on:click={showPhoto} />
  {/if}
</Modal>
