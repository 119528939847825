<script lang="ts">
  import { extra } from '$lib/stores/modal-state'
  import Modal from './Modal.svelte'
  import type { RoomFieldsFragment } from '$lib/queries/fragments/generated/RoomFields'
  import Title from '../typography/Title.svelte'
  import { groupRoomAmenities } from 'src/utils/stays/amenities'

  $: room = $extra['room-details'] as RoomFieldsFragment
  $: groupedAmenities = groupRoomAmenities(room?.amenities)
</script>

<Modal sizeClass="sm:max-w-3xl" open={true} title={room.name} isFullScreen={false}>
  <div class="mb-8 mr-2 prose-sm prose">
    {@html room.description}
  </div>

  {#if room.amenities}
    <div class="grid grid-cols-2 col-span-2 gap-4 mt-1 lg:grid-cols-3 pb-16">
      {#each groupedAmenities as amenitiesGroup (amenitiesGroup.category)}
        <div class={amenitiesGroup.amenities.length > 11 ? 'col-span-2' : 'col-span-1'}>
          <Title>{amenitiesGroup.category}</Title>
          <ul
            class={`mt-1 list-disc pl-4 text-sm space-y-1
          ${amenitiesGroup.amenities.length > 11 ? 'grid grid-cols-2 gap-x-4' : ''}`}
          >
            {#each amenitiesGroup.amenities as amenity (amenity.id)}
              <li>{amenity.name}</li>
            {/each}
          </ul>
        </div>
      {/each}
    </div>
  {/if}
</Modal>
