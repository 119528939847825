<script lang="ts">
  import type { PhotoFieldsFragment } from '$lib/queries/fragments/generated/PhotoFields'
  import { titleCaseSpace } from 'src/utils/restrictions/title-case'
  import { groupPhotosByCategory } from 'src/utils/stays/photos'
  import MasonryPhotos from './MasonryPhotos.svelte'

  export let allPhotos: PhotoFieldsFragment[]

  $: photosWithCategory = groupPhotosByCategory(allPhotos)
</script>

<div class="hidden pb-24 xl:block md:m-8">
  {#each photosWithCategory as cat, index (index)}
    <div class="grid grid-cols-12 mb-4 lg:my-16 lg:gap-x-8">
      <div class="col-span-12 lg:col-span-2">
        <div class="sticky my-4 text-xl font-semibold top-8">
          {titleCaseSpace(cat.category)}
        </div>
      </div>
      <div class="col-span-12 lg:col-span-10">
        <MasonryPhotos allPhotos={cat.photos} on:click />
      </div>
    </div>
  {/each}
</div>

<div class="xl:hidden">
  <MasonryPhotos {allPhotos} on:click />
</div>
