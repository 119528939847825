<script lang="ts">
  import Modal from './Modal.svelte'
  import { supportedCurrencies } from '$lib/utils/currency'
  import { Icon } from '@steeze-ui/svelte-icon'
  import { Check } from '@steeze-ui/heroicons'
  import Button from '../ui/Button.svelte'
  import modal from '$lib/stores/modal-state'
  import { getCurrencyCookie, setCurrencyCookie } from 'src/utils/localization'

  let selected = getCurrencyCookie()

  function handleSelectCurrency(id: string) {
    selected = id
    setCurrencyCookie(id)
  }
</script>

<Modal sizeClass="sm:max-w-3xl" open={true} title="Select Currency" isFullScreen={false}>
  <ul class="grid sm:grid-cols-2 gap-x-2">
    {#each supportedCurrencies as currency}
      {@const isActive = currency.id === selected}
      <li
        class={`flex text-sm items-center justify-between space-x-4 px-4 py-3 rounded-lg hover:bg-grey-md cursor-pointer ${
          isActive ? 'bg-grey-main' : 'bg-transparent'
        }`}
        on:click={() => handleSelectCurrency(currency.id)}
      >
        <div class="flex items-center">
          <p class="w-12 font-medium">{currency.id}</p>
          <p>{currency.name}</p>
        </div>
        <Icon src={Check} class={`w-4 h-4 ${isActive ? 'block' : 'hidden'}`} />
      </li>
    {/each}
  </ul>

  <div class="flex justify-end pb-16 sm:pb-0 mt-6">
    <Button size="md" eventName="resendConfirmation" on:click={() => modal.close()}>Close</Button>
  </div>
</Modal>
