<script lang="ts">
  import type { PhotoFieldsFragment } from '$lib/queries/fragments/generated/PhotoFields'
  import MasonryPhoto from './MasonryPhoto.svelte'

  export let allPhotos: PhotoFieldsFragment[]
  const minPhotos = Math.min(allPhotos.length, 40)

  let didAllLoad = false
  let loadCount = 0
  function imageDidLoad() {
    loadCount++
    didAllLoad = loadCount > minPhotos - 5
  }
</script>

{#if allPhotos.length > 5}
  <div
    class="block sm:columns-[240px] md:columns-[320px] space-y-2 sm:space-y-0 gap-2 lg:gap-4 break-inside-avoid  -mt-8 -mx-4 sm:-mx-6 xl:m-0"
  >
    {#each allPhotos as image, index}
      <MasonryPhoto {image} {index} on:click on:load={imageDidLoad} {didAllLoad} />
    {/each}
  </div>
{:else}
  <div
    class="block sm:columns-[240px] md:columns-[320px] space-y-2 sm:space-y-0 xl:columns-[400px] gap-2 lg:gap-4 break-inside-avoid  -mt-8 -mx-4 sm:-mx-6 xl:m-0"
  >
    {#each allPhotos as image, index}
      <MasonryPhoto {image} {index} on:click on:load={imageDidLoad} {didAllLoad} />
    {/each}
  </div>
{/if}
