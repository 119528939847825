<script lang="ts">
  import { extra } from '$lib/stores/modal-state'
  import Title from '../typography/Title.svelte'
  import Modal from './Modal.svelte'

  $: panelItems = $extra['checkin-policy']
  $: checkInDetails = panelItems.checkInDetails
</script>

<Modal sizeClass="sm:max-w-xl" open={true} title="Check In Instructions">
  <div class="py-4 px-2 space-y-6">
    {#if checkInDetails.checkInInstructions}
      <div>
        <Title>Check-In Instructions</Title>
        <div class="prose prose-sm">{@html checkInDetails.checkInInstructions}</div>
      </div>
    {/if}

    {#if checkInDetails.specialInstructions}
      <div>
        <Title>Additional Instructions</Title>
        <div class="prose prose-sm">{@html checkInDetails.specialInstructions}</div>
      </div>
    {/if}
  </div>
</Modal>
