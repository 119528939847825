<script lang="ts">
  import { navigating } from '$app/stores'
  import modal, { extra } from '$lib/stores/modal-state'
  import pricePreview from '$lib/stores/pricePreview'
  import solution from '$lib/stores/solution'
  import { toAmount } from '$lib/utils/currency'
  import { addBreadcrumb } from '@sentry/core'
  import { differenceInCalendarDays } from 'date-fns'
  import Amount from '../ui/Amount.svelte'
  import Button from '../ui/Button.svelte'
  import GuestSelectModal from './GuestSelectModal.svelte'
  import HotelSelectFooter from './HotelSelectFooter.svelte'

  $: panelItems = $extra['guest-select-hotel-page']

  $: selectedRooms = $pricePreview.selectedRooms ?? []
  $: totalAmount = $pricePreview.totalAmount ?? 0
  $: totalRooms = $pricePreview.totalRooms ?? 0
  $: isFetching = $pricePreview.isFetching ?? false

  $: checkIn = new Date(
    $solution?.hotelQuery?.checkIn?.year,
    $solution?.hotelQuery?.checkIn?.month - 1,
    $solution?.hotelQuery?.checkIn?.day,
  )
  $: checkOut = new Date(
    $solution?.hotelQuery?.checkOut?.year,
    $solution?.hotelQuery?.checkOut?.month - 1,
    $solution?.hotelQuery?.checkOut?.day,
  )
  $: numNights = differenceInCalendarDays(checkOut, checkIn)
  $: adults = $solution?.hotelQuery?.adultCount ?? 2
  $: children = $solution?.hotelQuery?.childAges ?? []

  $: currency = $solution?.hotelQuery?.currencyCode ?? 'USD'

  async function handleOccupancyChange(event: CustomEvent<{ adults: number; children: number[] }>) {
    const { adults: newAdults, children: newChildren } = event.detail

    addBreadcrumb({ level: 'info', message: 'Set Guest Selection', data: event.detail })

    $pricePreview.isFetching = true
    return solution.update({
      hotelQuery: {
        adultCount: newAdults,
        childAges: newChildren,
        currencyCode: currency,
        roomSelections: [],
      },
    })
  }

  function closeModal() {
    modal.close()
  }
</script>

<GuestSelectModal
  maxOccupancy={panelItems?.maxOccupancy}
  {adults}
  {children}
  on:change={handleOccupancyChange}
>
  <div class="pb-32" />
  <HotelSelectFooter
    {adults}
    {children}
    {checkIn}
    {checkOut}
    numSelectedRooms={selectedRooms?.length ?? 0}
    selectedRoomName={selectedRooms?.[0]?.room?.name}
    {totalRooms}
    hideOnLarge={false}
    blockPopUp={true}
  >
    <span slot="price">
      {#if totalRooms > 0 || isFetching}
        {#if selectedRooms.length > 0}
          <Amount
            loading={$navigating != undefined || isFetching}
            amount={toAmount(totalAmount, currency)}
            rounded
          />
          <span class="text-sm sm:mt-[2px] text-gray-800">total</span>
        {:else}
          <Amount
            loading={$navigating != undefined || isFetching}
            amount={toAmount(totalAmount / numNights, currency)}
            rounded
          /><span class="sm:mt-[2px] text-gray-800">+</span>
          <span class="text-sm sm:mt-[2px] text-gray-800">Night</span>
        {/if}
      {:else}
        <span class="text-sm sm:mt-[2px] text-black font-medium">Unavailable</span>
      {/if}
    </span>
    <Button slot="button" class="w-[80px]" on:click={closeModal}>Save</Button>
  </HotelSelectFooter>
</GuestSelectModal>
