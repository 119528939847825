<script lang="ts">
  import modal from '$lib/stores/modal-state'
  import LocationSelect, { type LocationItem } from '../hotels/LocationSelect.svelte'
  import Button from '../ui/Button.svelte'
  import HotelSelectFooter from './HotelSelectFooter.svelte'
  import Modal from './Modal.svelte'

  export let checkIn: Date
  export let checkOut: Date
  export let adults: number
  export let children: number[]
  export let location: LocationItem | null

  // const urql = getContextClient()
  // $: rates = queryStore<HotelRatesQuery, HotelRatesQueryVariables>({
  //   client: urql,
  //   query: HotelRatesDocument,
  //   requestPolicy: 'cache-only',
  //   variables: {
  //     id: 'htl_2BmmGxI8rXb1nEyovgmI44Iiqoe',
  //     checkIn: formatISO(checkIn, { representation: 'date' }),
  //     checkOut: formatISO(checkOut, { representation: 'date' }),
  //     adults: adults,
  //     childAges: children,
  //   },
  //   pause: !browser,
  // })

  // $: console.log(
  //   'RATES in calendar:',
  //   $rates?.data?.hotel?.rates?.edges?.map((e) => e.node.inclusivePrice / 100),
  // )

  function closeModal() {
    modal.close()
  }
</script>

<Modal open={true} isFullScreen={true} showClose={true}>
  <LocationSelect value={location} on:select />
  <div class="mt-24" />
  <HotelSelectFooter {adults} {children} {checkIn} {checkOut}>
    <Button slot="button" class="w-[80px]" on:click={closeModal}>Save</Button>
  </HotelSelectFooter>
</Modal>
