<script lang="ts">
  import modal from '$lib/stores/modal-state'
  import { Dialog, DialogOverlay, DialogTitle } from '@rgossiaux/svelte-headlessui'
  import { createEventDispatcher } from 'svelte'
  import { fade, fly } from 'svelte/transition'
  import CloseButton from './CloseButton.svelte'

  export let open = false
  let modelClassName = ''
  export { modelClassName as modalClass }
  export let isFullScreen = false
  export let isBlack = false
  export let isMap = false
  export let size = 'sm:max-w-md'
  export { size as sizeClass }
  export let title = ''
  export let showClose = true
  export let overflowScroll = true

  const dispatch = createEventDispatcher()
  function onClose() {
    dispatch('close')
    modal.close()
  }
</script>

<Dialog
  as="div"
  {open}
  class={`fixed inset-0 ${
    isFullScreen ? 'z-40' : 'z-50'
  } cursor-pointer overscroll-contain overflow-y-auto ${modelClassName}`}
  on:close={onClose}
>
  <div
    class={`flex items-end justify-center min-h-screen h-full ${
      isFullScreen ? 'relative' : 'p-0'
    } `}
  >
    <div transition:fade|local={{ duration: 100 }}>
      <DialogOverlay
        class="fixed inset-0 bg-black transform-gpu bg-opacity-20 backdrop-blur-sm backdrop-filter"
      />
    </div>

    <div
      class={`
        ${
          isMap
            ? 'fixed top-0 bg-white overscroll-contain cursor-auto overflow-clip h-full w-full'
            : isBlack
            ? 'fixed top-0 bg-black overscroll-contain cursor-auto overflow-clip h-full w-full py-16'
            : 'inline-block w-screen cursor-auto p-4 sm:p-6 text-left bg-white top-0'
        }
        ${
          !isFullScreen
            ? size +
              ' h-screen sm:h-auto relative sm:my-auto sm:align-middle sm:rounded-lg shadow-xl'
            : !isBlack
            ? 'fixed h-full'
            : ''
        }
        ${overflowScroll && !isBlack ? 'overflow-y-scroll' : ''}
      `}
      transition:fly|local={{ duration: 150, opacity: 0, y: 40 }}
    >
      {#if $$slots.header}
        <div
          class={`${
            isFullScreen ? 'top-0' : 'top-6'
          } rounded-t-md fixed bg-white px-4 pb-2 pt-4 z-20 border-b border-grey-dk left-0 right-0`}
        >
          <slot name="header" />
        </div>
        <div class="mt-20 sm:mt-16" />
      {/if}
      <div
        class={`${
          !isFullScreen
            ? size + ' h-[calc(100%-4rem)]'
            : !isBlack && !isMap
            ? 'max-w-screen-2xl h-full'
            : 'h-full'
        } sm:h-full sm:mx-auto`}
      >
        {#if title}
          <DialogTitle
            as="h3"
            class="relative flex items-start justify-between pb-2 mb-4 text-lg font-medium leading-6 text-gray-900"
          >
            <span class="mt-2 leading-6 mr-8">{title}</span>
            {#if showClose}
              <div
                class={`${
                  isFullScreen
                    ? 'top-4 right-4 lg:top-8 lg:right-8'
                    : 'sm:top-auto sm:relative sm:right-0'
                } top-4 right-4 z-30 fixed`}
              >
                <CloseButton on:click={onClose} />
              </div>
            {/if}
          </DialogTitle>
        {:else if showClose}
          <div
            class={`${
              isFullScreen
                ? 'top-4 right-4 lg:top-8 lg:right-8'
                : 'sm:top-auto sm:relative sm:right-0'
            } top-4 right-4 z-30 fixed`}
          >
            <CloseButton on:click={onClose} />
          </div>
        {/if}
        {#if $$slots.footer}
          <div class={`overflow-y-auto ${!isFullScreen ? size : ''}`}>
            <slot />
          </div>
        {:else}
          <slot />
        {/if}
      </div>

      {#if $$slots.actions}
        <div class="grid grid-cols-3 gap-4 mt-4">
          <slot name="actions" />
        </div>
      {/if}

      {#if $$slots.footer}
        <div class="mt-20" />
        <div class="fixed bottom-0 left-0 right-0 px-4 py-2 bg-white border-t border-grey-dk">
          <slot name="footer" />
        </div>
      {/if}
    </div>
  </div>
</Dialog>
