<script lang="ts">
  import type { PhotoFieldsFragment } from '$lib/queries/fragments/generated/PhotoFields'

  import cdnImageURL, { cdnSrcSetHiDPI } from 'src/utils/cdn'
  import { onMount, tick } from 'svelte'
  import { fade } from 'svelte/transition'

  export let photos: PhotoFieldsFragment[]
  export let index = 0

  $: photo = photos[index]

  const imageSrcCache = new Set<string>()

  function handleImageClick(event: MouseEvent) {
    let width = (event.target as HTMLImageElement).clientWidth
    if (event.offsetX / width > 0.5) {
      forward()
    } else if (index > 0) {
      back()
    } else {
      forward()
    }
  }

  $: collection = photos
  $: totalPhotos = collection.length ?? 1

  const forward = () => {
    preload(collection)

    index = (index + 1) % totalPhotos
  }
  const back = () => {
    preload(collection.slice(index, index - 10))

    if (index === 0) {
      index = totalPhotos - 1
    } else {
      index = (index - 1) % totalPhotos
    }
  }

  let key = ''

  onMount(() => {
    tick().then(() => {
      preload(collection.slice(0, 10))
    })
  })

  function preload(photos: PhotoFieldsFragment[]) {
    photos.forEach((photo) => {
      if (imageSrcCache.has(photo.id)) return
      imageSrcCache.add(photo.id)
      let img = new Image()
      img.onerror = (err) => {
        console.warn('Preload err', err)
      }
      // img.onload = () => imageSrcCache.add(photo.id)
      img.src = cdnImageURL({ src: photo.url, width: photo.width })
      img.srcset = cdnSrcSetHiDPI(photo.url, photo.width)
    })
  }

  function handleKeydown(event: KeyboardEvent) {
    key = event.key
    switch (key) {
      case 'ArrowLeft':
        back()
        break
      case 'ArrowRight':
        forward()
        break
    }
  }
</script>

<svelte:window on:keydown={handleKeydown} />

<div class="w-full h-full mx-auto">
  <button
    type="button"
    disabled={totalPhotos < 2}
    on:click|preventDefault|stopPropagation={back}
    class="absolute z-10 flex items-center justify-center disabled:hidden w-8 h-8 bg-white rounded-full opacity-75 bottom-8 lg:bottom-[calc(50%+6px)] hover:opacity-100 left-8 ring-black focus:ring-black focus:outline-none"
  >
    <img
      src={`/marketing/icons/left-arrow-carousel.svg`}
      width={7}
      height={14}
      class="-ml-[2px]"
      alt="left arrow"
      aria-label="left arrow"
    />
  </button>

  <div class="absolute flex justify-center w-full font-medium text-white top-8">
    {index + 1} / {totalPhotos}
  </div>

  <div
    class="flex items-center justify-center w-full h-full carousel gap-x-1 aspect-x-5 aspect-y-5"
  >
    {#if photo}
      <img
        in:fade
        on:click|preventDefault|stopPropagation={handleImageClick}
        src={cdnImageURL({ src: photo.url, width: photo.width })}
        srcset={cdnSrcSetHiDPI(photo.url, photo.width)}
        alt={photo?.caption ?? 'photo'}
        width={photo.width}
        loading={index == 0 ? 'eager' : 'lazy'}
        class="object-cover w-auto aspect-x-5 aspect-y-5"
      />
    {/if}
  </div>

  <div class="absolute flex justify-center w-full font-medium text-white bottom-8">
    {collection[index] ? collection[index].category : ''}
  </div>

  <button
    type="button"
    disabled={totalPhotos < 2}
    on:click|preventDefault|stopPropagation={forward}
    class=" absolute z-10 flex items-center justify-center disabled:hidden w-8 h-8 bg-white rounded-full opacity-75 bottom-8 lg:bottom-[calc(50%+6px)] hover:opacity-100 right-8 ring-black focus:ring-black focus:outline-none"
  >
    <img
      src={`/marketing/icons/right-arrow-carousel.svg`}
      width={7}
      height={14}
      class="-mr-[2px]"
      alt="right arrow"
      aria-label="right arrow"
    />
  </button>
</div>
