<script lang="ts">
  import { createEventDispatcher } from 'svelte'
  const dispatch = createEventDispatcher()
  function handleClick() {
    dispatch('click')
  }
</script>

<button
  aria-label="close"
  on:click={handleClick}
  class="flex items-center justify-center w-8 h-8 text-black bg-white rounded-full shadow-md appearance-none opacity-100 focus:outline-none hover:text-black"
>
  <svg
    class="w-5 h-5"
    fill="none"
    stroke="currentColor"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width={2}
      d="M6 18L18 6M6 6l12 12"
    />
  </svg>
</button>
