<script lang="ts">
  import { newClient } from '$lib/api/client'
  import { BookingService } from '$lib/gen/booking/v1/service_connectweb'
  import type { GetRefundPolicyResponse } from '$lib/gen/booking/v1/service_pb'
  import {
    CancelReservationDocument,
    type CancelReservationMutation,
    type CancelReservationMutationVariables,
  } from '$lib/queries/generated/CancelReservation'
  import modal, { extra } from '$lib/stores/modal-state'
  import { toAmount } from '$lib/utils/currency'
  import type { AlertMessage } from '$lib/utils/pricing/find-selected-rooms'
  import { Code, type ConnectError } from '@bufbuild/connect-web'
  import type { PartialMessage } from '@bufbuild/protobuf'
  import { getContextClient } from '@urql/svelte'
  import { reportError } from 'src/utils/track'
  import { onMount } from 'svelte'
  import CancellationPolicy from '../hotels/CancellationPolicy.svelte'
  import Spinner from '../Spinner.svelte'
  import Alert from '../ui/Alert.svelte'
  import Amount from '../ui/Amount.svelte'
  import Button from '../ui/Button.svelte'
  import Modal from './Modal.svelte'

  $: modalData = $extra['confirm-cancel']
  let inFlight = false
  let showButtons = false

  // Policy is fetched directly from Expedia to calculate exact amount at the current time.
  let policy: PartialMessage<GetRefundPolicyResponse> | null = null

  function toggleShowButtons() {
    showButtons = true
  }

  let bookingSvc = newClient(BookingService)
  onMount(() => {
    setTimeout(toggleShowButtons, 3000)
    bookingSvc
      .getRefundPolicy({ reservationId: modalData.reservationId })
      .then((res) => {
        policy = res
      })
      .catch((err: ConnectError) => {
        policy = null

        switch (err.code) {
          case Code.NotFound:
            policy = {
              cancelRefundAmount: 0,
              cancelRefundCurrency: 'USD',
            }
        }
      })
  })

  const urql = getContextClient()

  let alert: AlertMessage | null = null

  async function cancelReservation() {
    inFlight = true
    alert = null

    return urql
      .mutation<CancelReservationMutation, CancelReservationMutationVariables>(
        CancelReservationDocument,
        {
          id: modalData.reservationId,
        },
      )
      .toPromise()
      .then((data) => {
        if (data.error == null) {
          modal.close()
          return
        }
        reportError(
          new Error('Failed to cancel reservation', {
            cause: {
              reservationId: modalData.reservationId,
              error: data.error,
            },
          }),
        )
        inFlight = false
        alert = {
          type: 'error',
          title: 'Error while handling request',
          message: `We experienced an error while cancelling your reservation. An error report has been filed and one of our friendly team members will resolve the issue and reach out to you soon. Please email support@airheart.com with reference code ${modalData.referenceNumber} if you have any questions.`,
        }
      })
      .catch((err) => {
        inFlight = false
        alert = {
          type: 'error',
          title: 'Error while handling request',
          message: `We experienced an error while cancelling your reservation. An error report has been filed and one of our friendly team members will resolve the issue and reach out to you soon. Please email support@airheart.com with reference code ${modalData.referenceNumber} if you have any questions.`,
        }
        reportError(
          new Error('Failed to cancel reservation', {
            cause: {
              reservationId: modalData.reservationId,
              error: err,
            },
          }),
        )
      })
  }
</script>

<Modal open={true} sizeClass="sm:max-w-2xl" title="Manage Reservation" isFullScreen={false}>
  {#if alert}
    <Alert {...alert} />
  {:else}
    <h2>Cancellation Policy</h2>
    <CancellationPolicy penalties={modalData.penalties} />

    <div class="my-4 space-y-4 border-t border-grey-dk py-4">
      <h2>Refund Details</h2>
      {#if policy}
        {#if policy.cancelRefundAmount < 0}
          <p class="rounded-md bg-emerald-50 p-4 text-emerald-800">
            If you cancel now, you will receive a refund of
            <span class="font-mono font-medium bg-emerald-100 text-emerald-900">
              <Amount amount={toAmount(policy.cancelRefundAmount * -1)} />
              ({policy.cancelRefundCurrency})
            </span>
            {#if policy.paymentMethodLastFour.length == 4}
              returned to your card ending in
              <span class="font-mono bg-emerald-100 text-emerald-900"
                >{policy.paymentMethodLastFour}</span
              >
            {/if}
          </p>
          <p class="text-sm">
            Refunds can only be returned to the original method of payment used to make the
            reservation and will usually appear on your statement immediately, but may be subject to
            your card issuer's processing time of 1-10 business days for funds to be available.
          </p>
        {:else}
          <p class="rounded-md bg-red-50 p-4 text-sm font-medium">
            This reservation is no longer refundable.
          </p>
        {/if}
      {:else}
        <div class="flex items-center gap-x-4 p-4"><Spinner /><span>Calculating…</span></div>
      {/if}

      <div class="font-medium text-sm">
        <p>There is no way to recover your reservation after canceling.</p>
      </div>
    </div>
  {/if}

  <div
    class="pb-6 sm:pb-0 pt-4 flex flex-col-reverse sm:flex-row items-center gap-4 justify-end bg-white"
  >
    <button
      disabled={inFlight || (policy == null && !showButtons)}
      class="w-full sm:w-auto flex focus:outline-none focus:ring-none disabled:bg-grey-main disabled:text-gray-500 disabled:cursor-not-allowed hover:text-black items-center justify-center h-10 px-6 py-2 text-sm font-semibold rounded-full bg-grey-main hover:bg-grey-md"
      on:click={() => modal.close()}>Keep Reservation</button
    >
    <Button
      loading={inFlight}
      disabled={inFlight || (policy == null && !showButtons)}
      priority="secondary"
      class="w-full sm:w-auto text-sm px-auto gap-x-1 flex-shrink-0 h-10 disabled:cursor-not-allowed"
      on:click={cancelReservation}
    >
      Cancel Reservation
    </Button>
  </div>
</Modal>
