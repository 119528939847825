<script lang="ts">
  import { extra } from '$lib/stores/modal-state'
  import { sortBy } from 'lodash-es'
  import Modal from './Modal.svelte'

  $: countries = $extra['countries']
  $: sortableCountries = sortBy(countries, 'name')
</script>

<Modal open={true} title="All Countries">
  <div class="min-w-min sm:w-96">
    <div class="sm:flex sm:items-start">
      <div class="w-full sm:mt-0 sm:mx-4 sm:text-left">
        <!-- <h3
          class="my-4 text-2xl font-medium leading-6 tracking-tight text-gray-900 sm:mt-8"
          id="modal-headline"
        >
          Full List of Countries
        </h3> -->
        {#each sortableCountries as country, index}
          <span>
            {countries?.length > 1 && index == countries?.length - 1 ? `, and ` : index > 0 && ', '}
            <span class="font-medium">{country.name}</span>
          </span>
        {/each}
      </div>
    </div>
  </div>
</Modal>
