<script lang="ts" context="module">
  export type EventTypes = {
    changeStarRating: { starRatingRange: number[] }
    changeAmenities: { code: number }
    clear: void
  }
</script>

<script lang="ts">
  import { debounce } from 'lodash-es'
  import { createEventDispatcher, tick } from 'svelte'
  import RangeSlider from 'svelte-range-slider-pips'
  import { filterItems } from './HotelFiltersModalWrapper.svelte'

  export let starRatingRange: number[] = [1, 5]
  // export let minPrice: number | null = null
  // export let maxPrice: number | null = null
  export let amenityCodes: number[] = []

  const dispatch = createEventDispatcher<EventTypes>()
  const debouncedDispatch = debounce(dispatch, 500, { leading: true })

  async function handleChangeStarRating(
    event: CustomEvent<{
      activeHandle: number
      previousValue: number
      startValue: number
      value: number
      values: [number, number]
    }>,
  ) {
    tick().then(() =>
      debouncedDispatch('changeStarRating', {
        starRatingRange: event.detail.values,
      }),
    )
  }

  async function handleChangeAmenities(value: number) {
    tick().then(() =>
      debouncedDispatch('changeAmenities', {
        code: value,
      }),
    )
  }
</script>

<div class="flex flex-col overflow-x-hidden gap-y-4">
  <div class="block text-sm text-gray-900">
    <h4 class="font-semibold">Amenities</h4>
    <div class="grid grid-cols-1 sm:grid-cols-2 m-2 gap-x-8">
      {#each filterItems.filter((val) => val.type == 'amenity') as amenity (amenity.slug)}
        <label class="cursor-pointer">
          <input
            on:change={() => handleChangeAmenities(amenity.value)}
            type="checkbox"
            checked={amenityCodes.includes(amenity.value)}
            class="checked:bg-black checked:focus:bg-black checked:focus:ring-black checked:ring-black checked:hover:ring-black ring-black"
            value={amenity.slug}
          />
          <span class="px-2 text-sm leading-6 lowercase">{amenity.name}</span>
        </label>
      {/each}
    </div>
  </div>
  <!-- <div class="block text-sm text-gray-900">
    <h4 class="font-semibold">Price Range</h4>
    <div class="grid grid-cols-2 m-2 gap-x-8">
      {#each filterItems.filter((val) => val.type == 'price') as amenity (amenity.slug)}
        <label class="cursor-pointer">
          <input
            type="checkbox"
            class="checked:bg-black checked:focus:bg-black checked:focus:ring-black checked:ring-black checked:hover:ring-black ring-black"
            value={amenity.slug}
          />
          <span class="px-2 text-sm leading-6 lowercase">{amenity.name}</span>
        </label>
      {/each}
    </div>
  </div> -->

  <div class="block text-sm text-gray-900">
    <h4 class="font-semibold">Star Rating</h4>
    <div class="p-4">
      <RangeSlider
        on:change={handleChangeStarRating}
        range
        pips
        all="label"
        hoverable
        bind:values={starRatingRange}
        pushy
        step={1}
        min={1}
        max={5}
      />
    </div>
  </div>

  <!-- <div class="block text-sm text-gray-900">
    <h4 class="font-semibold">Chain</h4>
  </div>

  <div class="block text-sm text-gray-900">
    <h4 class="font-semibold">Brand</h4>
  </div> -->

  <div class="block text-sm text-gray-900">
    <h4 class="font-semibold">Disability Friendly</h4>
    <div class="grid grid-cols-1 m-2">
      {#each filterItems.filter((val) => val.type == 'disability') as amenity (amenity.slug)}
        <label class="cursor-pointer">
          <input
            on:change={() => handleChangeAmenities(amenity.value)}
            type="checkbox"
            checked={amenityCodes.includes(amenity.value)}
            class="checked:bg-black checked:focus:bg-black checked:focus:ring-black checked:ring-black checked:hover:ring-black ring-black"
            value={amenity.slug}
          />
          <span class="px-2 text-sm lowercase">{amenity.name}</span>
        </label>
      {/each}
    </div>
  </div>
</div>

<style global lang="postcss">
  /* main slider element */
  .rangeSlider {
    --range-slider: rgb(218 218 218 / var(--tw-bg-opacity)); /* slider main background color */
    --range-handle-inactive: rgb(31 41 55 / var(--tw-bg-opacity)); /* inactive handle color */
    --range-handle: rgb(31 41 55 / var(--tw-bg-opacity)); /* non-focussed handle color */
    --range-handle-focus: rgb(0 0 0 / var(--tw-bg-opacity)); /* focussed handle color */
  }
  .rangeSlider.vertical {
  } /* if slider is vertical */
  .rangeSlider.focus {
  } /* if slider is focussed */
  .rangeSlider.range {
  } /* if slider is a range */
  .rangeSlider.min {
  } /* if slider is a min-range */
  .rangeSlider.max {
  } /* if slider is a max-range */
  .rangeSlider.pips {
  } /* if slider has visible pips */
  .rangeSlider.pip-labels {
  } /* if slider has labels for pips */
  /* slider handles */
  .rangeSlider > .rangeHandle {
  } /* positioned wrapper for the handle/float */
  .rangeSlider > .rangeHandle.active {
  } /* if a handle is active in any way */
  .rangeSlider > .rangeHandle.press {
  } /* if a handle is being pressed down */
  .rangeSlider > .rangeHandle.hoverable {
  } /* if the handles allow hover effect */
  .rangeSlider > .rangeHandle > .rangeNub {
  } /* the actual nub rendered as a handle */
  .rangeSlider > .rangeHandle > .rangeFloat {
  } /* the floating value above the handle */
  /* slider range */
  .rangeSlider > .rangeBar {
    @apply bg-black;
  } /* the range between the two handles */
  /* slider pips */
  .rangeSlider > .rangePips {
  } /* the container element for the pips */
  .rangeSlider > .rangePips.focus {
  } /* if slider is focussed */
  .rangeSlider > .rangePips.vertical {
  } /* if slider is vertical */
  .rangeSlider > .rangePips > .pip {
  } /* an individual pip */
  .rangeSlider > .rangePips > .pip.first {
  } /* the first pip on the slider */
  .rangeSlider > .rangePips > .pip.last {
  } /* the last pip on the slider */
  .rangeSlider > .rangePips > .pip.selected {
  } /* if a pip is selected */
  .rangeSlider > .rangePips > .pip.in-range {
  } /* if a pip is somewhere in the range */
  .rangeSlider > .rangePips > .pip > pipVal {
  } /* the label for the pip */
</style>
