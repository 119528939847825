<script lang="ts">
  import { toPBDate } from '$lib/solution/date'
  import solution from '$lib/stores/solution'
  import modal from '$lib/stores/modal-state'
  import Button from '../ui/Button.svelte'
  import CalendarModal from './CalendarModal.svelte'
  import { differenceInCalendarDays } from 'date-fns'
  import { addBreadcrumb } from '@sentry/core'

  $: checkIn = new Date(
    $solution?.hotelQuery?.checkIn?.year,
    $solution?.hotelQuery?.checkIn?.month - 1,
    $solution?.hotelQuery?.checkIn?.day,
  )
  $: checkOut = new Date(
    $solution?.hotelQuery?.checkOut?.year,
    $solution?.hotelQuery?.checkOut?.month - 1,
    $solution?.hotelQuery?.checkOut?.day,
  )
  $: numNights = differenceInCalendarDays(checkOut, checkIn)

  async function handleDatesChange(event: CustomEvent<{ checkIn: Date; checkOut: Date }>) {
    const { checkIn: newCheckIn, checkOut: newCheckOut } = event.detail
    addBreadcrumb({ level: 'info', message: 'Set Date Selection', data: event.detail })

    const newNumNights = differenceInCalendarDays(newCheckOut, newCheckIn)
    numNights = newNumNights

    return solution.update({
      hotelQuery: {
        checkIn: toPBDate(newCheckIn),
        checkOut: toPBDate(newCheckOut),
        roomSelections: [],
      },
    })
  }

  function closeModal() {
    modal.close()
  }
</script>

<CalendarModal {checkIn} {numNights} {checkOut} on:change={handleDatesChange}>
  <div class="pb-24" />
  <div
    class="h-20 z-[11] w-full flex justify-end items-center px-4 py-2 text-black bg-white sm:h-28 border-grey-dk border-t fixed left-0 bottom-0"
  >
    <Button
      aria-label="close"
      key="close-btn"
      on:click={closeModal}
      class="px-4 py-2 border rounded-full shadow-sm border-grey-md focus:outline-none"
    >
      Save
    </Button>
  </div>
</CalendarModal>
