<script lang="ts">
  import { goto, preloadData } from '$app/navigation'
  import { page } from '$app/stores'
  import { HotelSearchQuery_Selector } from '$lib/gen/hotel/v1/query_pb'
  import type { HotelPropertyWhereInput, HotelTheme, PropertyCategory } from '$lib/graphql/types'
  import {
    HotelSearchDocument,
    type HotelSearchQuery,
    type HotelSearchQueryVariables,
  } from '$lib/queries/generated/QueryHotelSearch'
  import { buildSolutionURL, travelSolutionFromURL, updateSolution } from '$lib/solution/router'
  import modal, { extra } from '$lib/stores/modal-state'
  import searchMapBounds from '$lib/stores/searchMapBounds'
  import { getContextClient, queryStore } from '@urql/svelte'
  import { formatISO } from 'date-fns'
  import { gqlRateOptionFromSolution, gqlSalesChannelFromSolution } from 'src/utils/enums'
  import { selectors } from 'src/utils/stays/selectors'
  import GoogleSearchMap from '../trips/GoogleSearchMap.svelte'
  import Modal from './Modal.svelte'

  $: panelItems = $extra['map-search']
  $: hotels = panelItems.hotels
  $: numNights = panelItems.numNights
  $: destination = panelItems.destination
  $: mapSearchCoordinates = panelItems.mapSearchCoordinates
  $: travelSolution = travelSolutionFromURL($page.url)
  $: hasLocation = travelSolution?.hotelQuery?.locationScopeId?.length > 4
  $: currency = travelSolution?.hotelQuery?.currencyCode ?? 'USD'

  $: checkIn = new Date(
    travelSolution?.hotelQuery?.checkIn?.year,
    travelSolution?.hotelQuery?.checkIn?.month - 1,
    travelSolution?.hotelQuery?.checkIn?.day,
  )
  $: checkOut = new Date(
    travelSolution?.hotelQuery?.checkOut?.year,
    travelSolution?.hotelQuery?.checkOut?.month - 1,
    travelSolution?.hotelQuery?.checkOut?.day,
  )

  const urql = getContextClient()
  let pageSize = 50

  $: where =
    mapSearchCoordinates != null
      ? {
          withinMapBounds: {
            northEast: {
              lat: mapSearchCoordinates?.neLat,
              lng: mapSearchCoordinates?.neLng,
            },
            southWest: {
              lat: mapSearchCoordinates?.swLat,
              lng: mapSearchCoordinates?.swLng,
            },
          },
          and: [],
          or: [],
        }
      : ({
          withinLocationID: travelSolution?.hotelQuery?.locationScopeId,
          and: [],
          or: [],
        } as HotelPropertyWhereInput)

  $: if (
    travelSolution?.hotelQuery?.minStarRating > 0 &&
    travelSolution?.hotelQuery?.maxStarRating > 0
  )
    where.and = [
      { starRatingNEQ: 0 },
      { starRatingGTE: travelSolution?.hotelQuery?.minStarRating },
      { starRatingLTE: travelSolution?.hotelQuery?.maxStarRating },
    ]

  $: if (
    travelSolution?.hotelQuery?.withAmenityCodes &&
    travelSolution?.hotelQuery?.withAmenityCodes?.length > 0
  )
    where.and = [{ containsAmenityIDs: travelSolution?.hotelQuery?.withAmenityCodes }]

  $: if (travelSolution?.hotelQuery.filter != HotelSearchQuery_Selector.UNSPECIFIED) {
    const categoryIDs =
      selectors.find((s) => s.type == 'category' && s.id == travelSolution?.hotelQuery?.filter)
        ?.contentIds ?? []
    const themeIDs =
      selectors.find((s) => s.type == 'theme' && s.id == travelSolution?.hotelQuery?.filter)
        ?.contentIds ?? []
    where.or = [
      ...themeIDs.map<HotelPropertyWhereInput>((id) => ({
        containsThemes: [id as HotelTheme],
      })),
      ...categoryIDs.map<HotelPropertyWhereInput>((cat) => ({
        category: cat as PropertyCategory,
      })),
    ].filter(Boolean)
  }

  $: rateOption = gqlRateOptionFromSolution(travelSolution?.hotelQuery.rateOption)
  $: salesChannel = gqlSalesChannelFromSolution(travelSolution?.hotelQuery.salesChannel)

  $: search = queryStore<HotelSearchQuery, HotelSearchQueryVariables>({
    client: urql,
    query: HotelSearchDocument,
    requestPolicy: 'cache-and-network',
    variables: {
      first: pageSize,
      adults: travelSolution?.hotelQuery?.adultCount,
      childAges: travelSolution?.hotelQuery?.childAges,
      checkIn: formatISO(checkIn, { representation: 'date' }),
      checkOut: formatISO(checkOut, { representation: 'date' }),
      rateOption: rateOption,
      salesChannel: salesChannel,
      currencyCode: currency,
      where,
    },
    pause: !hasLocation && mapSearchCoordinates == null,
  })

  $: if (mapSearchCoordinates != null && $search.data?.hotelsConnection?.edges?.length > 0) {
    hotels = $search.data?.hotelsConnection?.edges?.map((edge) => edge.node) ?? []
  }
  $: fetching = $search.fetching

  function searchBounds(event: CustomEvent) {
    const { neLat, neLng, swLat, swLng } = event.detail

    mapSearchCoordinates = {
      neLat,
      neLng,
      swLat,
      swLng,
    }

    searchMapBounds.setBounds(mapSearchCoordinates)
    updateSolution($page.url, {
      hotelQuery: {
        locationScopeId: '',
      },
    })
  }

  function buildHotelURL(hotelID: string) {
    let nextURL = new URL(`/stays/${hotelID}`, $page.url)
    return buildSolutionURL(travelSolution, nextURL)
  }

  function handleClickHotel(event: CustomEvent<string>) {
    let nextURL = buildHotelURL(event.detail)
    goto(nextURL.toString()).then(() => modal.close())
  }

  function preloadHotelPage(event: CustomEvent<string>) {
    let nextURL = buildHotelURL(event.detail)
    preloadData(nextURL.toString())
  }
</script>

<Modal open={true} isFullScreen={true} isMap={true} showClose={true}>
  <GoogleSearchMap
    isModal={true}
    {hotels}
    {destination}
    {currency}
    mapOpen={true}
    {numNights}
    isMobile={true}
    {mapSearchCoordinates}
    {fetching}
    on:bounds={searchBounds}
    on:click={handleClickHotel}
    on:preload={preloadHotelPage}
  />
</Modal>
