<script lang="ts">
  import { newClient } from '$lib/api/client'
  import { BookingService } from '$lib/gen/booking/v1/service_connectweb'
  import modal, { extra } from '$lib/stores/modal-state'
  import type { AlertMessage } from '$lib/utils/pricing/find-selected-rooms'
  import { Code, type ConnectError } from '@bufbuild/connect-web'
  import { validator } from '@felte/validator-zod'
  import { createForm } from 'felte'
  import { pluralize } from 'src/utils/text-helpers'
  import { slide } from 'svelte/transition'
  import { z } from 'zod'
  import Alert from '../ui/Alert.svelte'
  import Button from '../ui/Button.svelte'
  import InputWithLabel from '../ui/forms/InputWithLabel.svelte'
  import Modal from './Modal.svelte'

  $: modalData = $extra['resend-confirmation']
  let inFlight = false
  let message = null

  let bookingSvc = newClient(BookingService)

  let alert: AlertMessage | null = null

  type Addess = {
    email: string
    name: string
  }

  const schema = z.object({
    recipients: z
      .object({
        email: z.string().email().min(1, { message: 'Must be 1 or more characters long' }),
        name: z.string().min(1, { message: 'Must be 1 or more characters long' }),
      })
      .array()
      .min(0)
      .max(5)
      .optional(),
  })

  type Schema = z.infer<typeof schema>

  const { form, errors, setErrors, setFields, createSubmitHandler } = createForm({
    initialValues: { recipients: [] },
    onSubmit: async (values: Schema) => {
      inFlight = true
      message = null

      let extraEmails = values?.recipients?.map((addr) => `${addr.name} <${addr.email}>`)

      bookingSvc
        .sendConfirmationEmail({
          reservationId: modalData.reservationId,
          additionalEmails: extraEmails,
        })
        .then((result) => {
          alert = {
            type: 'info',
            message: `A new confirmation email has been sent to ${pluralize(
              result.notificationIds.length,
              'email address',
              'email addresses',
            )}.`,
            title: 'Confirmation email sent',
          }
          inFlight = false
        })
        .catch((err: ConnectError) => {
          switch (err.code) {
            case Code.Internal:
              message = 'An error occurred. Please try again.'
            // TODO: Handle internal error
          }

          inFlight = false
        })
    },
    extend: [validator({ schema })],
  })

  const altOnSubmit = createSubmitHandler()

  $: originalGuest = modalData?.guests?.map((g) => {
    return { email: g.email, name: g.givenName + ' ' + g.familyName }
  })?.[0]

  $: numRecipients = recipients.length + 1

  let recipients: Addess[] = []

  function removeRecipient() {
    if (recipients.length < 1) return
    recipients.pop()
    recipients = recipients
  }

  function addRecipient() {
    if (numRecipients >= 5) return
    recipients.push({ email: '', name: '' })
    recipients = recipients
  }

  function deleteRecipient(idx: number) {
    recipients.splice(idx, 1)
    recipients = recipients
  }
</script>

<Modal open={true} sizeClass="sm:max-w-2xl" title="Send Confirmation Email" isFullScreen={false}>
  {#if alert}
    <Alert {...alert} inline={true} />

    <div class="flex justify-end mt-6">
      <button
        class="flex
    flex-row
    items-center
    justify-center
    appearance-none
    transition-all
    duration-75
    hover:opacity-90
    text-center
    rounded-full
    whitespace-nowrap
    leading-4
    focus:outline-none
    font-semibold hover:bg-gray-700 bg-black text-white active:text-white disabled:bg-grey-main disabled:text-gray-500 active:bg-gray-800
    px-8 py-4"
        on:click={() => modal.close()}>Close</button
      >
    </div>
  {:else}
    <div class="my-4 space-y-6">
      <p>Enter the contact details of the recipients you'd like to send a confirmation email to.</p>

      <div class="flex items-center justify-between w-full mt-2">
        <h4 class="text-sm font-semibold md:ml-4">Recipients</h4>
        <div class="flex items-center justify-end gap-x-4">
          <button
            class="flex items-center justify-center p-4 rounded-full disabled:bg-white disabled:cursor-not-allowed bg-grey-main hover:bg-grey-md"
            on:click={removeRecipient}
            title={'remove adult'}
            disabled={numRecipients <= 1}
          >
            <img
              src={'/marketing/icons/remove.svg'}
              class="h-[12px]"
              width={12}
              height={12}
              loading="lazy"
              alt={`decrement icon`}
            />
          </button>
          <span class="flex items-center justify-center w-4">{numRecipients}</span>
          <button
            class="flex items-center justify-center p-4 rounded-full disabled:bg-white disabled:cursor-not-allowed bg-grey-main hover:bg-grey-md"
            on:click={addRecipient}
            title={'add adult'}
            disabled={numRecipients == 5}
          >
            <img
              src={'/marketing/icons/add.svg'}
              class="h-[12px]"
              width={12}
              height={12}
              loading="lazy"
              alt={`increment icon`}
            />
          </button>
        </div>
      </div>
      <form class="pb-8 sm:pb-0" id="email-form" use:form>
        <div class="flex items-center justify-between mb-6">
          <div class="flex flex-col md:flex-row gap-x-2 text-sm md:mx-4">
            <div>
              {originalGuest?.name}
            </div>
            <div>
              ({originalGuest?.email})
            </div>
          </div>
        </div>
        {#each recipients as recipient, i}
          <div class="flex justify-between items-start gap-2 mb-4">
            <div class="flex flex-col md:flex-row w-full gap-2">
              <InputWithLabel
                name="recipients.{i}.name"
                placeholder="Full Name"
                type="text"
                autoCapitalize="words"
                autoComplete="name"
                class="w-full"
                value={recipient.name}
                errors={$errors?.recipients?.[i]?.name}
              />
              <InputWithLabel
                name="recipients.{i}.email"
                type="email"
                class="w-full"
                placeholder="user@example.com"
                autoComplete="email"
                value={recipient.email}
                errors={$errors?.recipients?.[i]?.email}
              />
            </div>

            <button
              tabindex="-1"
              class="flex items-center flex-shrink-0 justify-center px-[18px] py-4 mt-1 rounded-full bg-grey-main hover:bg-grey-md"
              on:click|preventDefault|stopPropagation={() => deleteRecipient(i)}
              title={'delete child'}
            >
              <img
                src={'/marketing/icons/delete.svg'}
                class="h-[12px]"
                width={10}
                height={12}
                loading="lazy"
                alt={`delete icon`}
              />
            </button>
          </div>
        {/each}

        {#if message}
          <p transition:slide|local class="text-sm my-2 text-red-500" id="payment-message">
            {message}
          </p>
        {/if}

        <div class="flex justify-end mt-6 ">
          <Button
            loading={inFlight}
            disabled={inFlight}
            type="submit"
            id="submit"
            size="md"
            eventName="resendConfirmation"
            on:click={altOnSubmit}
          >
            Send
          </Button>
        </div>
      </form>
    </div>
  {/if}
</Modal>
