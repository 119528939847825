<script lang="ts">
  import modal, { extra } from '$lib/stores/modal-state'
  import Modal from './Modal.svelte'
  import Button from '../ui/Button.svelte'
  import { getContextClient, queryStore } from '@urql/svelte'
  import OriginSelector, { type CountryItem } from '../travel-restrictions/OriginSelector.svelte'
  import { setUserLocationCookie } from 'src/utils/get-user-location'
  import {
    AllCountriesDocument,
    type AllCountriesQuery,
    type AllCountriesQueryVariables,
  } from '$lib/queries/generated/QueryAllCountries'
  import { getNameFromCountryId } from 'src/utils/country-codes'
  import originLocation from '$lib/stores/originLocation'

  $: panelItems = $extra['change-origin']
  $: selectedOriginId = panelItems.originId
  $: selectedOrigin = {
    iconId: selectedOriginId,
    value: selectedOriginId,
    label: getNameFromCountryId(selectedOriginId),
  } as CountryItem

  function handleOriginCountryChange(e: CustomEvent) {
    selectedOrigin = e.detail
    setUserLocationCookie(selectedOrigin.value)
  }

  const client = getContextClient()
  $: countriesQuery = queryStore<AllCountriesQuery, AllCountriesQueryVariables>({
    client,
    query: AllCountriesDocument,
  })

  $: origins = $countriesQuery?.data?.countries.map((country) => ({
    iconId: country.id,
    label: country.name,
    value: country.id,
  }))
</script>

<Modal
  open={true}
  title="Select Your Country of Origin"
  isFullScreen={false}
  overflowScroll={false}
>
  <div class="p-4">
    <OriginSelector on:change={handleOriginCountryChange} value={selectedOrigin} items={origins} />
  </div>
  <svelte:fragment slot="actions">
    <span />
    <span />
    <Button
      id="book-stays-sidebar"
      priority="secondary"
      class="flex-shrink-0 h-10 text-sm px-auto gap-x-1"
      on:click={() => modal.close()}>Close</Button
    >
  </svelte:fragment>
</Modal>
