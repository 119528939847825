<script lang="ts">
  import { extra } from '$lib/stores/modal-state'
  import SignInForm from '../auth/SignInForm.svelte'
  import Modal from './Modal.svelte'

  let title = 'Sign Up or Log In'
  switch ($extra['signup'].intent) {
    case 'signIn':
      break
    case 'joinWaitlist':
      title = 'Join the Waitlist'
      break
    case 'subscribe':
      title = 'Subscribe to Changes'
      break
    case 'signUp':
      title = 'Sign Up'
      break
    case 'reserve':
      title = 'Log in or Sign Up to book'
      break
    case 'discount':
      title = 'Sign Up or Log In'
      break
  }
</script>

<Modal open={true} {title}>
  <SignInForm>
    {#if $extra['signup'].intent == 'signUp'}
      <p class="text-sm sm:text-base mb-2 sm:mb-4">Create your Airheart account.</p>
    {:else if $extra['signup'].intent == 'signIn'}
      <p class="text-sm sm:text-base mb-2 sm:mb-4">Access your Airheart account.</p>
    {:else if $extra['signup'].intent == 'joinWaitlist'}
      <p class="text-sm sm:text-base mb-2 sm:mb-4">
        Access special deals and discounts on upcoming products and features.
      </p>
      <p class="text-sm sm:text-base mb-2 sm:mb-4">We'll let you know when your number is up!</p>
    {:else if $extra['signup'].intent == 'subscribe'}
      <p class="mb-2 sm:mb-4 text-sm sm:text-base">
        Stay informed of changes to restrictions so you can plan accordingly.
      </p>
      <p class="mb-4 text-sm sm:text-base sm:mt-2 sm:mb-10">
        Automatically join the waitlist to plan a trip with Airheart.
      </p>
    {:else if $extra['signup'].intent == 'reserve'}
      <!-- <p class="mb-2 sm:mb-4 text-sm sm:text-base">Reserve hotels and manage your bookings</p> -->
    {:else if $extra['signup'].intent == 'discount'}
      <p class="mb-4 text-sm sm:text-base sm:mt-2 sm:mb-10">
        Receive discounted prices on all participating properties when you become a member.
      </p>
    {/if}
  </SignInForm>
</Modal>
