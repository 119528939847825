<script lang="ts">
  import { getDescription } from '$lib/helpers/fee-descriptions'
  import { extra } from '$lib/stores/modal-state'
  import { toAmount } from '$lib/utils/currency'
  import Title from '../typography/Title.svelte'
  import Amount from '../ui/Amount.svelte'
  import Modal from './Modal.svelte'
  import solution from '$lib/stores/solution'

  $: priceItems = $extra['price-breakdown']
  $: quotePriceItems = priceItems.quotePriceItems
  $: totalAmount = priceItems.totalAmount
  $: totalPropertyFees = priceItems.totalPropertyFees

  $: baseRateCurrency = $solution?.hotelQuery?.currencyCode ?? 'USD'
</script>

<Modal title="Price Breakdown" open={true} isFullScreen={true} showClose={true}>
  <div class="w-full pt-4 space-y-2 text-black border-t border-grey-dk">
    {#each quotePriceItems?.filter((item) => item.isIncludedInTotal) as priceItem}
      <div class="flex items-center justify-between">
        <Title>{getDescription(priceItem.category)}</Title>
        <Amount amount={toAmount(priceItem.amount, baseRateCurrency)} class="text-sm" />
      </div>
    {/each}

    <div class="flex items-center justify-between">
      <Title>Total due now ({baseRateCurrency})</Title>
      <Amount amount={toAmount(totalAmount, baseRateCurrency)} class="font-semibold" />
    </div>
    {#if totalPropertyFees > 0}
      <div
        class="flex items-center justify-between w-full pt-4 mt-6 space-x-8 border-t border-grey-dk"
      >
        <p class="flex-1 text-xs leading-4 tracking-wide text-black 2xl:text-sm">
          Additional fee collected at the property
        </p>
        <Amount
          amount={toAmount(totalPropertyFees, baseRateCurrency)}
          class="text-sm font-semibold"
        />
      </div>
    {/if}
  </div>
</Modal>
