import type { PhotoFieldsFragment } from '$lib/queries/fragments/generated/PhotoFields'

export type PhotosWithCategory = { category: string; photos: PhotoFieldsFragment[] }

export function groupPhotosByCategory(allPhotos: PhotoFieldsFragment[]): PhotosWithCategory[] {
  if (allPhotos == null) return []
  const groupedPhotos = []
  Object.keys(photoCategories).forEach((cat) => {
    const allInCat = photoCategories[cat]
    const photosInGroup = allPhotos.filter((a) => allInCat.includes(a.category.toString()))
    if (photosInGroup.length > 0 && cat != 'Featured Image') {
      groupedPhotos.push({
        category: cat,
        photos: photosInGroup,
      })
    }
  })
  return groupedPhotos.sort((a, b) => (a.photos.length > b.photos.length ? -1 : 1))
}

export const photoCategories = {
  Lobby: [
    'Interior Entrance',
    'Lobby',
    'Reception',
    'Lobby Sitting Area',
    'Check-in/Check-out Kiosk',
  ],
  'Point of Interest': ['Point of Interest'],
  'Guest facilities and services': [
    'Concierge Desk',
    'Hair Salon',
    'Property Amenity',
    'Gift Shop',
    'Laundry Room',
    'Vending Machine',
    'ATM/Banking On site',
    'Lockers',
    'Library',
    'Ballroom',
    'Banquet Hall',
    'Reception Hall',
    'Chapel',
    'Indoor Wedding',
    'Fireplace',
    'Marina',
    'Cleanliness standards',
    'Cleanliness badge',
    'Property manager',
  ],
  'Bar (on property)': ['Lobby Lounge', 'Bar', 'Lounge', 'Sports Bar'],
  Room: ['Room', 'Capsule'],
  'Room Layout': [
    "Children's Theme Room",
    'In-Room Dining',
    'Living Area',
    'Living Room',
    'Terrace/Patio',
    'Balcony',
    'Ryokan Dining',
    'In-room fitness',
    'In-Room Business Center',
    'Lanai',
    'Private Pool',
    'Private Spa Tub',
    'Floor plan',
    'Wardrobe',
    'Dining room',
  ],
  Bedding: ['Extra Beds'],
  Kitchen: ['Private Kitchen', 'Private Kitchenette'],
  'Room service': ['Room Service - Dining'],
  Conveniences: [
    'Room Amenity',
    'Safe',
    'Iron/Ironing Board',
    'Laundry',
    'Massage in Accommodation',
  ],
  'Kitchen Features': ['Mini-Refrigerator', 'Microwave'],
  'Room views': [
    'View from Room',
    'Beach/Ocean View',
    'Lake View',
    'Mountain View',
    'Balcony View',
    'Street View',
    'City View',
    'Garden View',
    'Courtyard View',
    'Desert view',
    'Golf view',
    'Water view',
    'Resort View',
    'Valley View',
    'Vineyard View',
    'Park View',
    'Hill View',
  ],
  Refreshments: ['Minibar', 'Coffee and/or Coffee Maker'],
  Romantic: ['Champagne service', 'Couples Dining'],
  'Climate control': ['Air conditioning', 'Heating', 'Fan'],
  'Entertainment and Tech': ['Television', 'Tech'],
  'Bathroom types': ['Bathroom', 'Shared bathroom'],
  'Bathroom Features': [
    'Jetted Tub',
    'Deep Soaking Bathtub',
    'Bathroom Sink',
    'Bathroom Shower',
    'Bathroom Amenities',
  ],
  'Featured Image': ['Featured Image'],
  Pool: [
    'Pool',
    "Children's Pool",
    'Indoor Pool',
    'Outdoor Pool',
    'Natural Pool',
    'Infinity Pool',
    'Water Park',
    'Aqua Center',
    'Waterslide',
    'Outdoor Spa Tub',
    'Indoor Spa Tub',
    'Indoor/Outdoor Pool',
    'Pool Waterfall',
    'Rooftop Pool',
    'Swim-up Bar',
    'Exercise/Lap Pool',
    'Sauna',
    'Steam Room',
    'Poolside Bar',
  ],
  Fitness: ['Fitness Facility', 'Gym', 'Aerobics Facility', 'Fitness Studio', 'Yoga', 'Pilates'],
  'Adventure activities': [
    'Rock Climbing Wall - Indoor',
    'Bicycling',
    'Hunting',
    'Archery',
    'Hiking',
    'Outdoor Rock Climbing',
    'Ropes Course (Team Building)',
    'Horse Riding',
    'Cave Exploring',
    'Ecotours',
    'Helicopter/Plane Tours',
    'Skydiving',
    'Ziplining',
    'Bowling',
    'Scooter/Moped',
    'Segway Tours',
  ],
  Spa: [
    'Spa',
    'Nail Salon',
    'Vichy Shower',
    'Sauna',
    'Steam Room',
    'Turkish Bath',
    'Spa Reception',
    'Treatment Room',
    'Massage',
    'Spa Treatment',
    'Facial',
  ],
  'Hot springs': ['Hot springs'],
  'Ryokan facilities': ['Public Bath', 'Hot Springs', 'Yukata', 'Japanese garden'],
  Accessibility: [
    'Wheelchair-accessible van parking',
    'Accessible parking',
    'Elevator',
    'Accessible bathroom',
    'Guest wheelchair',
    'Handrails in hallways',
    'Handrails in stairways',
    'Hand-held showerhead',
    'Portable bathtub seat',
    'Portable shower seat',
    'Raised toilet seat',
  ],
  'Sports Facility': [
    'Sports Facility',
    'Tennis Court',
    'Basketball Court',
    'Sport Court',
    'Mini-Golf',
  ],
  'Water-based activities': [
    'Boating',
    'Fishing',
    'Kayaking',
    'Rafting',
    'Rowing',
    'Surfing/boogie boarding',
  ],
  Golf: ['Golf', 'Golf Cart', 'Indoor Golf Driving Range', 'Pro Shop'],
  'Safari and game': ['Game Viewing', 'Game Drive', 'Safari'],
  'Skiing and snowboarding': [
    'Snow and Ski Sports',
    'Ski Hill',
    'Skiing',
    'Snowboarding',
    'Equipment Storage',
  ],
  'Winter sports': ['Snowshoeing', 'Snowmobiling', 'Ice Skating', 'Sledding', 'Snow tubing'],
  Casino: ['Casino'],
  'Games and Entertainment': ['Theater Show', 'Karaoke Room', 'Billiards', 'Nightclub'],
  'Family and Children': [
    'Game Room',
    'Arcade',
    "Children's Area",
    'Day Care',
    "Children's Play Area - Indoor",
    "Children's Play Area - Outdoor",
    'Birthday Party Area',
    "Children's Activities",
  ],
  Parking: ['RV or Truck Parking', 'Electric vehicle charging station', 'Parking'],
  Pets: ['Pet-Friendly', 'Pet-friendly amenities'],
  'Property Amenity': ['Miscellaneous'],
  Winery: ['Winery', 'Vineyard', 'Tasting room'],
  'Dining (on property)': [
    'Dining',
    'Coffee Service',
    'Restaurant',
    'Buffet',
    'Delicatessen',
    'Cafe',
    'Coffee Shop',
    'Snack Bar',
    'Food Court',
    'Family Dining',
    'Food and Drink',
    'Outdoor Dining',
  ],
  Breakfast: ['Breakfast Area', 'Breakfast buffet', 'Breakfast Meal'],
  'Common Kitchen Area': ['Shared Kitchen', 'Shared Kitchen Facilities'],
  Beach: ['Beach Bar', 'Beach'],
  Interior: ['Interior', 'Hallway', 'Staircase', 'Interior Detail'],
  'Business and conference': ['Meeting Facility', 'Business Center'],
  'Club/Executive': ['Executive Lounge'],
  Shopping: ['Designer store', 'Shopping mall'],
  Exterior: [
    'Property Grounds',
    'Front of Property',
    'Property Entrance',
    'Front of Property - Evening/Night',
    'Exterior detail',
    'Exterior',
    'Aerial View',
  ],
  'Outdoor Areas': [
    'Garden',
    'Courtyard',
    'Terrace/Patio',
    'Porch',
    'Gazebo',
    'Outdoor Wedding Area',
    'Outdoor Banquet Area',
    'Sundeck',
    'Fountain',
    'BBQ/Picnic Area',
    'Lake',
    'Dock',
    'Rooftop terrace',
    'Property map',
  ],
  'Airport transport and shuttles': ['Airport Shuttle'],
  'Transportation and shuttles': [
    'City Shuttle',
    'Train Station Shuttle',
    'Casino Shuttle',
    'Cruise Terminal Shuttle',
    'Ferry Terminal Shuttle',
    'Shopping Centre Shuttle',
    'Theme Park Shuttle',
  ],
  'Location Highlights': [
    'View from Property',
    'Land View from Property',
    'City View from Property',
  ],
  'Building Features': ['Building design'],
}
