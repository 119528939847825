<script lang="ts">
  import { extra } from '$lib/stores/modal-state'
  import CancellationPolicy from '../hotels/CancellationPolicy.svelte'
  import Modal from './Modal.svelte'

  $: panelItems = $extra['cancellation-policy']
</script>

<Modal sizeClass="sm:max-w-xl" open={true} title="Cancellation Policy">
  <CancellationPolicy penalties={panelItems.penalties} />

  <div class="text-sm text-gray-600 bg-neutral-100 p-4 rounded-md">
    <p>
      <span class="font-semibold">Please note:</span> This cancellation policy is defined by the hotel
      property and is not controlled by Airheart.
    </p>
    <p>Under no circumstances will a refund be made to an alternate card.</p>
  </div>
</Modal>
