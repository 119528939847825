<script lang="ts">
  import modal, { extra } from '$lib/stores/modal-state'
  import SherpaIframe from '../visas/SherpaIframe.svelte'
  import CancellationPolicyDescriptions from './CancellationPolicyDescriptions.svelte'
  import ChangeOriginModal from './ChangeOriginModal.svelte'
  import CheckInInstructionsModal from './CheckInInstructionsModal.svelte'
  import ConfirmCancelModal from './ConfirmCancelModal.svelte'
  import CountriesModal from './CountriesModal.svelte'
  import HotelFiltersModalWrapper from './HotelFiltersModalWrapper.svelte'
  import HotelPhotos from './HotelPhotos.svelte'
  import LocationSearchModalWrapper from './LocationSearchModalWrapper.svelte'
  import RoomDetails from './RoomDetailsModal.svelte'
  import SearchModal from './SearchModal.svelte'
  import ShareModal from './ShareModal.svelte'
  import SignInModal from './SignInModal.svelte'
  import MapSearchModal from './MapSearchModal.svelte'
  import PriceBreakdownModal from './PriceBreakdownModal.svelte'
  import CalendarModalStaysSearch from './CalendarModalStaysSearch.svelte'
  import CalendarModalHotelPage from './CalendarModalHotelPage.svelte'
  import GuestSelectModalStaysSearch from './GuestSelectModalStaysSearch.svelte'
  import GuestSelectModalHotelPage from './GuestSelectModalHotelPage.svelte'
  import HotelAmenitiesModal from './HotelAmenitiesModal.svelte'
  import ResendConfirmation from './ResendConfirmation.svelte'
  import LocalizationSelectModal from './LocalizationSelectModal.svelte'
</script>

{#if $modal == 'signup'}
  <!-- Handles all sub modal states, or 'intents' -->
  <SignInModal />
{/if}

{#if $modal == 'countries'}
  <CountriesModal />
{/if}

{#if $modal == 'share'}
  <ShareModal />
{/if}

{#if $modal == 'search'}
  <SearchModal />
{/if}

{#if $modal == 'sherpa-iframe'}
  <SherpaIframe url={$extra['sherpa-iframe']} />
{/if}

{#if $modal == 'hotel-filters'}
  <HotelFiltersModalWrapper />
{/if}

{#if $modal == 'hotel-photos'}
  <HotelPhotos />
{/if}

{#if $modal == 'room-details'}
  <RoomDetails />
{/if}

{#if $modal == 'hotel-amenities'}
  <HotelAmenitiesModal />
{/if}

{#if $modal == 'calendar'}
  <CalendarModalStaysSearch />
{/if}

{#if $modal == 'calendar-hotel-page'}
  <CalendarModalHotelPage />
{/if}

{#if $modal == 'guest-select'}
  <GuestSelectModalStaysSearch />
{/if}

{#if $modal == 'guest-select-hotel-page'}
  <GuestSelectModalHotelPage />
{/if}

{#if $modal == 'location-search'}
  <LocationSearchModalWrapper />
{/if}

{#if $modal == 'cancellation-policy'}
  <CancellationPolicyDescriptions />
{/if}

{#if $modal == 'checkin-policy'}
  <CheckInInstructionsModal />
{/if}

{#if $modal == 'confirm-cancel'}
  <ConfirmCancelModal />
{/if}

{#if $modal == 'resend-confirmation'}
  <ResendConfirmation />
{/if}

{#if $modal == 'change-origin'}
  <ChangeOriginModal />
{/if}

{#if $modal == 'map-search'}
  <MapSearchModal />
{/if}

{#if $modal == 'price-breakdown'}
  <PriceBreakdownModal />
{/if}

{#if $modal == 'localization-select'}
  <LocalizationSelectModal />
{/if}
